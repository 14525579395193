import Command from '@ckeditor/ckeditor5-core/src/command';

export default class CtaButtonCommand extends Command {
  execute() {
    this.editor.model.change((writer) => {
      const text = prompt('Enter Button text');
      const linkTo = prompt('Enter the link');

      if (text && typeof text === 'string' && text.length && linkTo && typeof linkTo === 'string' && linkTo.length) {
        this.editor.model.insertContent(createButton(writer, text, linkTo));
      }
    });
  }
  refresh() {
    const model = this.editor.model;
    /* Get actual selection (selected range, or cursor position) */
    const selection = model.document.selection;
    /* Get allowed parent, defined in the editing class */
    const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'Button');
    /* Set enabled state */
    this.isEnabled = allowedIn !== null;
  }

  init() {
    console.log('CtaButtonCommand#init');
  }
}

function createButton(writer, text, linkTo) {
  const Button = writer.createElement('Button', {
    alt: text,
    href: linkTo,
  });

  return Button;
}
