import {createSlice} from '@reduxjs/toolkit';
const initialState = {
  name: '',
  description: '',
  lang: [],
  primaryLang: 'en',
  blocks: {},
};
export const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    set: (state, {payload}) => {
      Object.entries(payload).forEach(([key, value]) => {
        state[key] = value;
      });
    },
    clear: (state, action) => {
      return initialState;
    },
    setData: (state, {payload}) => {
      state.data = {...payload};
    },
    setBlock: (state, action) => {
      const {id} = action.payload;
      state.blocks[id] = action.payload;
    },
    removeBlock: (state, action) => {
      delete state.blocks[action.payload];
    },
   

  /*  Change: use var to remove any attribute desired.
     So instead of next_secondary could be any data.
 */    
     removeSecondaryButton: (state, action) => {
      const {id, blockId} = action.payload;
      console.log(state.blocks[blockId].data.next_secondary)
      delete state.blocks[blockId].data.next_secondary;
    },

     removeBlockDecision: (state, action) => {
      const {id, blockId} = action.payload;
      delete state.blocks[blockId].data.decisions[id];
    },
    removeBlockReviews: (state, action) => {
      const {id, blockId} = action.payload;
      delete state.blocks[blockId].data.reviews[id];
    },
    removeBlockVariable: (state, action) => {
      const {id, blockId} = action.payload;
      delete state.blocks[blockId].data.variables[id];
    },
    removeBlockFlow: (state, action) => {
      const {id, blockId} = action.payload;
      state.blocks[blockId].data.options[id]['hasUniqueFlow'] = false;
    },
    removeDataBlock: (state, action) => {
      const {key, blockId} = action.payload;
      delete state.blocks[blockId].data.data[key];
    },
    removeBlockCondition: (state, action) => {
      const {id, blockId} = action.payload;
      delete state.blocks[blockId].data.conditions[id];
    },
    setBlockData: (state, {payload}) => {
      state.blocks[payload.id].data = payload.data;
    },
    setBlocks: (state, {payload}) => {
      state.blocks = payload;
    },


    setChatbotData: (state, {payload}) => {
      state.data = {...payload};
    },
    /* Seems duplicate */
    setChatbotData0: (state, {payload}) => {
      state.data = {...payload};
    },
  },
});
