import {Chatbot} from '../states/actions';

export const onSuccess = (data) => {
  try {
    const {block} = data;
    Chatbot.setBlock({id: block._id, editMode: false});
    Chatbot.setBlockData(block._id, block);
  } catch (error) {
    console.error(error);
  }
};

export const onBackgroundSave = (chatbot) => {
  try {
    Chatbot.set(chatbot, true);
    Object.values(chatbot.blocks).forEach((block) => {
      if (block.type !== 'step') {
        Chatbot.setBlock({id: block.id, editMode: false});
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export const onEdit = (node) => {
  Chatbot.setBlock({id: node.id, editMode: true});
};

export const onSetStart = async (node) => {
  Chatbot.set({start: node}, true);
  const response = await Chatbot.setBlockData(node, {}, true);
  if (!response.error) {
    Chatbot.setBlock({id: node, editMode: false});
  } else {
    let {error, message} = response;
    window.nError({title: 'Opps!', text: message, icon: 'fa-exclamation-triangle', error});
  }
};

export const onClose = (id) => {
  Chatbot.setBlock({id, editMode: false});
};


export const onRemove = (id) => {
  Chatbot.removeBlock(id, true);
};

export const isLightColor = (color) => {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substr(0, 2), 16);
  const c_g = parseInt(hex.substr(2, 2), 16);
  const c_b = parseInt(hex.substr(4, 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness > 155;
};
