import React, {useRef, useEffect, useState} from 'react';
import {Handle} from 'reactflow';
import {useSelector} from 'react-redux';
import Select from 'react-select';

import {Chatbot} from '../../states/actions';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {ColorPicker} from '../ColorPicker';
import {InputComponent, SelectComp, IntNodeComponent} from './Components'


export const CalculationBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());

  if (!block) {
    return null;
  }
  const {
    data,
  } = block;

  return (
    <>
      <IntNodeComponent name="Calculation" icon="calculation" data={data} node={node} start={start}/>
    </>
  );
});


export const CalculationEditor = ({nodeid, block, data}) => {

  

 
  if (!block) {
    return null;
  }
  //Väärtus, mida salvestatakse datasse ei ole praegu mitte value vaid on label
  const variableOptions = Chatbot.getBlockVariables();
  let variables_unit = [
    {
      label: 'Date (DD.MM.YYYY)',
      value: 'date',
      content: 'date',
    },
    {
      label: 'Days (differace)',
      value: 'days',
      content: 'days',
    },
    {
      label: 'Months (differace)',
      value: 'months',
      content: 'months',
    },
    {
      label: 'Years (differace)',
      value: 'years',
      content: 'years',
    },
  ];
  let variables_operator = [
    {
      label: '+ days',
      value: 'add_days',
      content: 'add_days',
    },
    {
      label: '+ weeks',
      value: 'add_weeks',
      content: 'add_weeks',
    },
    {
      label: '+ years',
      value: 'add_years',
      content: 'add_years',
    },
    {
      label: '- days',
      value: 'subtract_days',
      content: 'subtract_days',
    },
    {
      label: '- weeks',
      value: 'subtract_weeks',
      content: 'subtract_weeks',
    },
    {
      label: '- years',
      value: 'subtract_years',
      content: 'subtract_years',
    },
  ];


  function add(v){
    const d = document.getElementById('formula');
    Chatbot.setBlockData(block.id, {formula: data.formula + v})
    if(d)d.value =  data.formula + v;
  }
  function setmath(){
    Chatbot.setBlockData(block.id, {method: 'math'})

  }
  function setdura(){
    Chatbot.setBlockData(block.id, {method: 'duration'})
  }

  const handleBlur = (e) => {   
    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
  };


  return (
    <>
                <div className='tab-container'>
                   <div className={`tab btn-section ${data.method === "math" ? 'active' : ''}`} onClick={() => setmath()}>
                      <span></span>
                      Math
                    </div>
                    <div className={`tab btn-section ${data.method === "duration" ? 'active' : ''}`} onClick={() => setdura()}>
                      <span></span>
                      Date - Duration
                    </div>
                  </div>

                    {data.method === 'duration' && (

                                  
                                <div>

                                  <div className="mb-2"><br/>
                                  </div>


                                  <SelectComp data={data} name="Output" dataname="unit" placeholder="Choose unit" options={variables_unit} block={block}/>

                                

                                  <div className="mb-2"><br/></div>

                                  <SelectComp data={data} name="Date" dataname="startDate" placeholder="Select start" options={variableOptions} block={block} value="label"/>
                    
                                  {data.unit !== 'date' && (
          
                                  <SelectComp data={data} name="Date 2" dataname="endDate" placeholder="Select end" options={variableOptions} block={block} value="label"/>

                                  )}

                                  {data.unit === 'date' && (
                                      <>

                                    {/* Operator */}  
                                    <SelectComp data={data} name="Operator" dataname="operator" placeholder="Choose operation" options={variables_operator} block={block}/>

                                    {/* Amount */}
                                    <InputComponent data={data} name="Amount" dataname="amount" onBlur={handleBlur}/>

                                    </>
                                  )}
                                  
                                    
                                  </div>      
                    )}


              

                    { data.method === 'math' && (  



                                  <div>
                                  <div className="mb-2">
                                        <br/>
                                  </div>

                                  <InputComponent data={data} name="Formula" dataname="formula" onBlur={handleBlur}/>
                                  <InputComponent data={data} name="Round to" dataname="roundto" onBlur={handleBlur}/>
   
                                  <div className=" mb-3">
                                      
                                          <button 
                                          className="mx-1 mt-1 col-2 btn-calc"  onClick={() => add('+')}>+
                                          </button>
                                          <div className='spacer'></div>
                                          <button 
                                          className="mx-1 mt-1 col-2 btn-calc"  onClick={() => add('-')}>-
                                          </button>
                                          <div className='spacer'></div>

                                          <button 
                                          className="mx-1 mt-1 col-2 btn-calc"  onClick={() => add('*')}>*
                                          </button>

                                          <button 
                                          className="mx-1 mt-1 col-2 btn-calc"  onClick={() => add('/')}>/
                                          </button>
                                          <div className='spacer'></div>

                                          <button 
                                          className="mx-1 mt-1 col-2 btn-calc"  onClick={() => add('(')}>&#40;
                                          </button>
                                          <div className='spacer'></div>

                                          <button 
                                          className="mx-1 mt-1 col-2 btn-calc"  onClick={() => add(')')}>&#41;
                                          </button>
                                  </div>

                            
                                  <div className="mb-2">
                                        <label htmlFor="firstName" className="form-label">
                                          Insert variable
                                        </label>
                                        <Select
                                          id="firstName"
                                          name="firstName"
                                          placeholder="Add variable to formula"
                                          className="form-control-sm "
                                          classNamePrefix=""
                                          options={variableOptions}
                                          value="{variableOptions.find((item) => item.label === data.first_name)}"
                                          onChange={(option) => {
                                            add('{'+ option.label + '}');
                                          }}
                                        />
                                    
                                   </div>
                                
                                  </div>  

                    )} 
    </>
  )
}