import React, {useRef, useState, useEffect, useContext} from 'react';
import {Handle, useStore} from 'reactflow';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onEdit, isLightColor} from '../../helpers/block-helper';


import {User} from '../../states/actions';
import {PreviewComponent, NodeComponent} from './Components'
import { GlobalStateContext } from '../GlobalState';

/* const zoomSelector = (s) => s.transform[2]; */




export const TextBlock = (node) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);  

  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const {_id} = useSelector(User.get());
  const optionSelectRef = useRef(null);
  const bot = useSelector(Chatbot.get());
  
  const [previewMode, setPreviewMode] = useState(globalState.preview ? undefined : false);
 /*  const zoom = useStore(zoomSelector); */


 useEffect(() => {
  setPreviewMode(globalState.preview);
}, [globalState.preview]);


  if (!block) {
    return null;
  }
  const {data} = block;

  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');



  return (
    <>
      {!previewMode &&(
        <>
        {/* <div>TEEEEEEEEEME</div>
        <Handle id="source" className="block-handle text-center" type="target" position="top"></Handle>
        <Handle id="next" className="block-handle text-center" type="source" position="bottom"></Handle> */}
              <NodeComponent bot={bot} block={block} name="Text" icon="text" data={data} node={node} start={start} /* zoom={zoom} *//>
         </>
      )}

      {previewMode && (
        //<div className={`block-body node`} onClick={review ? null : () => onEdit(node)}>
              <PreviewComponent bot={bot} data={data} node={node} start={start}/>
        //</div>
      )}

    
     
    </>
  );
};
