import React, {useRef, useEffect, useState, useContext} from 'react';
import {Handle, useStore} from 'reactflow';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {ColorPicker} from '../ColorPicker';
import Select from 'react-select';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import {validations, ckEditorBasic, moneyCurrency} from '../../config';
import {InputComponent, SelectComp, TextareaComponent, PreviewComponent, NodeComponent} from './Components'
import { GlobalStateContext } from '../GlobalState';

const zoomSelector = (s) => s.transform[2];


export const ReviewBlock = React.memo((node) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);  


  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const bot = useSelector(Chatbot.get());

  const [previewMode, setPreviewMode] = useState(globalState.preview ? undefined : false);
  const zoom = useStore(zoomSelector);



  useEffect(() => {
    setPreviewMode(globalState.preview);
}, [globalState.preview]);


  if (!block) {
    return null;
  }
  const {data, data: {reviews = {}}} = block;




  return (
    <>
      {/* {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="fas fa-square-root-alt"></i> Review
            </div>

            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                className="ms-1 me-2 icon fa fa-times"
                onClick={() => onClose(block.id)}
              ></i>
            </div>
          </div>
          <div className="block-body nodrag">

          <div className="mb-2">
               <br></br>       
              <input
                type="checkbox"
                id="isProgress"
                name="isProgress"
                className="mr-2"
                defaultChecked={data.isProgress}
                onBlur={(e) => Chatbot.setBlockData(node.id, {[e.target.name]: e.target.checked})}
              />
              <label htmlFor="isProgress" className="form-label">
                Is part of the progress
              </label>
              <br></br>
               <label htmlFor="progress" className="form-label">
                Step:
              </label>
              <div>
                <input
                  type="input"
                  id="progress"
                  name="progress"
                  className="form-control col-2 col" 
                  placeholder="number"
                  defaultValue={data.progress}
                  onBlur={(e) => {
                    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                  }}
                />
                <div className="col ml-2 mr-2" > / </div>
                 <input
                  type="input"
                  id="maxprogress"
                  name="maxprogress"
                  className="form-control col-2 col" 
                  placeholder="number"
                  defaultValue={data.maxprogress}
                  onBlur={(e) => {
                    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                  }}
                />
                  <input
                  type="input"
                  id="progressheader"
                  name="progressheader"
                  className="form-control"
                  placeholder="name"
                  defaultValue={data.progressheader}
                  onBlur={(e) => {
                    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                  }}
                />
              </div>
              <br></br>
              <br></br>
         
            </div>

          <div className="mb-2">
              <label htmlFor="headtext" className="form-label">
                Header for content
              </label>
              <input
                type="input"
                id="headtext"
                name="headtext"
                className="form-control"
                placeholder="Insert main question"
                defaultValue={data.headtext}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                }}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="content" className="form-label d-none">
                Content
              </label>
              <div id="content">
                <CKEditor
                  config={ckEditorBasic}
                  editor={ClassicEditor}
                  data={data.content}
                  onBlur={(e, editor) => {
                    Chatbot.setBlockData(block.id, {content: editor.getData()});
                  }}
                />
              </div>
            </div>


            





            {Object.keys(reviews)
              .sort()
              .map((key, i) => {
                const item = reviews[key];
                return (
                  <div key={item.id} className="input-group mb-3">

                    <Select
                      className="form-control form-control-sm"
                      placeholder="Select"
                      id={`reviews.${item.id}.element`}
                      name={`reviews.${item.element}`}
                      defaultValue={item.element}
                      options={variableOptions}
                    
                    
                      value={variableOptions.find((item) => item.value === data.reviewelement)}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: 0,
                          border: 'none',
                        }),
                      }}
                    />

            

                    <Handle id={item.id} className="block-handle block-handle-right" type="source" position="right" />
                    <span
                      className="input-group-text btn btn-outline-danger border-0"
                      onClick={(e) => removeReviewField(e, item.id)}
                    >
                      <i className="far fa-trash-alt"></i>
                    </span>
                  </div>
                );
              })}

            <div id="reviews" className="input-group">
              <label htmlFor="reviews" className="d-none">
                Reviews
              </label>


              <Select
                className="form-control form-control-sm"
                placeholder="Select"
                id="reviewelement"
                name="reviewelement"
                options={variableOptions}
                onKeyPress={(e) => (e.key === 'Enter' ? addReviewField(e) : e)}
                ref={refTxtReviewelement}
                value={variableOptions.find((item) => item.label === data.reviewelement)}
                onKeyDown={async (e) => {
                  if (e.code === 'Enter' && e.key === 'Enter') {
                    const selectedOption = refTxtReviewelement.current.state.label;
                    if (selectedOption) {
                      console.log(selectedOption.label);
                    }
                  }
                }}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: 0,
                    border: 'none',
                  }),
                }}
              />
               <span className="input-group-text btn btn-outline-success border-0" onClick={(e) => addReviewField(e)}>
                <i className="fa fa-plus"></i>
              </span>



          
            </div>




            <div className="mb-2">
              <label htmlFor="buttontext" className="form-label">
                Button text:
              </label>
              <input
                type="input"
                id="buttontext"
                name="buttontext"
                className="form-control"
                placeholder="Button text"
                defaultValue={data.buttontext}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                }}
              />
            </div>












          </div>
        </div>
      )} */}


      {!previewMode &&(
        <NodeComponent bot={bot} block={block} name="Review" icon="review" data={data} node={node} start={start} zoom={zoom}/>

      )}
      {previewMode &&(
        // <div className={`block-body node`} onClick={review ? null : () => onEdit(node)}>
         <PreviewComponent bot={bot} data={data} node={node} start={start} elements={reviews}/>
        // </div>

      )}

    </>
  );
});


export const ReviewEditor = ({nodeid, block, data, reviews}) => {

  const refTxtReviewelement = useRef(null);

  if (!block) {
    return null;
  }

  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');
  
  const variableOptions = Chatbot.getBlocksToReview();

 console.log(variableOptions, reviews)

  const addReviewField = (e) => {
    e.preventDefault();
    const id = Date.now().toString();
    const element = refTxtReviewelement.current.state.value;
    if (element !== '') {
      Chatbot.setBlockData(nodeid, {reviews: {[id]: {id, element}}});
      refTxtReviewelement.current.value = '';
    }
  };
  const removeReviewField = (e, id) => {
    e.preventDefault();
    Chatbot.removeBlockReviews(nodeid, id);
  };

    return (
      <>
        <div className='section'>REVIEW BLOCKS</div>

       

            <div id="reviews">
            

              <label htmlFor="reviews" className="d-none">
                Reviews
              </label>



              <div className="mb-4 single-input">

                  <Select
                      className="form-control form-control-sm editor-select"
                      classNamePrefix="editor-select"
                      placeholder="Select"
                      id="reviewelement"
                      name="reviewelement"
                      options={variableOptions}
                      onKeyPress={(e) => (e.key === 'Enter' ? addReviewField(e) : e)}
                      ref={refTxtReviewelement}
                      value={variableOptions.find((item) => item.label === data.reviewelement)}
                      onKeyDown={async (e) => {
                        if (e.code === 'Enter' && e.key === 'Enter') {
                          const selectedOption = refTxtReviewelement.current.state.label;
                          if (selectedOption) {
                            console.log(selectedOption.label);
                          }
                        }
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: 0,
                          border: 'none',
                        }),
                      }}
                  />
        
              <button onClick={(e) => addReviewField(e)} className="fa fa-plus icon"></button>


              </div>


              
          
            </div>

            {Object.keys(reviews)
                  .sort()
                  .map((key, i) => {
                    const item = reviews[key];
                    return (
                      <>
                        <div key={item.id} className="single-input my-3">

                          <div className="form-control valuefield">{item.element.label}</div>
                          <button  onClick={(e) => removeReviewField(e, item.id)} className="fa fa-trash-alt icon red"></button>

                        </div>
                        </>
                    );
              })}
      </>
    )
}