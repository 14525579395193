import { createSlice } from '@reduxjs/toolkit';

export const clientSlice = createSlice({
  name: 'clients',
  initialState: {
    clients: [],
    loading: false,
    error: null,
  },
  reducers: {
    set: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export default clientSlice.reducer;
