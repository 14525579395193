/**
 * @module plugin/CtaButton
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import CtaButtonEditing from './CtaButtonEditing';
import CtaButtonUI from './CtaButtonUI';

/**
 * The CButton feature.
 *
 * This is a "glue" plugin which loads the {@link module:plugin/CtaButton/CtaButtonEditing~CtaButtonEditing ctaButton editing feature}
 * and {@link module:plugin/CtaButton/CtaButtonUI~CtaButtonUI ctaButton UI feature}.
 *
 * @extends module:core/plugin~Plugin
 */

/* Create the button plugin */
export default class CtaButton extends Plugin {
  /**
   * @inheritDoc
   */

  static get requires() {
    /* This plugin requires the editing and UI extention */
    return [CtaButtonEditing, CtaButtonUI];
  }

  /**
   * @inheritDoc
   */

  static get pluginName() {
    // makes the plugin availabe in the PluginCollection.get('name') (otherwise the plugin is only availabe using it's constructor)
    return 'CtaButton';
  }

  init() {
/*     console.log('CtaButton#init');
 */  }
}
