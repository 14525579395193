import {Auth} from '../libs/auth';
import {User} from '../states/actions';

export const renderIfAuthenticated = ({component, history}) => {
  let user = Auth.authenticate();
  if (user) {
    return component;
  } else {
    //first check if user exists in session
    (async () => {
      let user = await User.getSession();
      if (user) {
        history.push(history.location.pathname);
      } else {
        localStorage.clear();
        history.push('/login');
      }
    })();
  }
};
