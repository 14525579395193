import {userSlice} from '../slices';
import store from '../../store';
import {axios} from '../../libs';
const {nError} = window;

const Actions = userSlice.actions;

const {dispatch, getState} = store;

const set = (data) => {
  dispatch(Actions.set(data));
};

const get = () => {
  return (state) => state.user;
};

const getSession = async () => {
  try {
    const {user} = await axios.get('/user');
    dispatch(Actions.set(user));
    return user;
  } catch (error) {
    nError({title: 'Access Denied'});
    return false;
  }
};

const isAdmin = () => {
  const _user = getState().user;
  return _user.role === 'admin';
};

export const User = {
  set,
  get,
  getSession,
  isAdmin,
};
