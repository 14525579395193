/**
 * @module plugin/CtaButton/CtaButtonEditing
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {toWidget} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import CtaButtonCommand from './CtaButtonCommand';

const inlineStyle = `display: block;
  background: #032097;
  color: white;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  width: auto;
  margin: 1rem auto;
  font-size: 1.2rem;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  `;

/**
 * The bold editing feature.
 *
 * It registers the `'bold'` command and introduces the `bold` attribute in the model which renders to the view
 * as a `<strong>` element.
 *
 * @extends module:core/plugin~Plugin
 */

export default class CtaButtonEditing extends Plugin {
  /**
   * @inheritDoc
   */
  static get pluginName() {
    return 'CtaButtonEditing';
  }
  /**
   * @inheritDoc
   */
  static get requires() {
    return [Widget];
  }
  /**
   * @inheritDoc
   */

  init() {
    /* Define the schema structure */
    this._defineSchema();

    /* Define the converters for the schema */
    this._defineConverters();

    /* Register insertion command */
    this.editor.commands.add('insertCtaButton', new CtaButtonCommand(this.editor));
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    schema.register('Button', {
      /* Is a self contained object */
      isObject: true,

      /* Allow in places where blocks are allowed */
      allowWhere: '$block',
      allowAttributes: ['alt', 'href', 'target', 'style', 'id'],
    });
  }

  _defineConverters() {
    const conversion = this.editor.conversion;

    let value = [];
    let href = [];

    /* Containing wrapper */
    // both failed
    conversion.for('upcast').elementToElement({
      model: 'Button',
      view: (modelElement) => {
        const id = modelElement._attrs.get('id');

        if (id === 'cta-btn') {
          const index = modelElement.index;
          const getHref = modelElement.getAttribute('href');
          const getAlt = modelElement.getAttribute('alt');
          if (getHref && getHref !== undefined) {
            href[index] = getHref;
          }
          if (getAlt && getAlt !== undefined) {
            value[index] = getAlt;
          }
          return modelElement;
        }
      },
    });

    // both run but stuck on button create
    conversion.for('dataDowncast').elementToElement({
      model: 'Button',
      view: (modelElement, {writer: viewWriter}) => {
        const index = modelElement.index;
        console.log('dataDowncast');

        const link = modelElement.getAttribute('href') || href[index];
        const altText = modelElement.getAttribute('alt') || value[index];

        const button = viewWriter.createContainerElement('a', {
          target: 'blank',
          href: link,
          alt: altText,
          style: inlineStyle,
          id: 'cta-btn',
          //class: 'email-button',
        });

        if (altText) {
          const innerText = viewWriter.createText(altText);
          viewWriter.insert(viewWriter.createPositionAt(button, 0), innerText);
        }

        return button;
      },
    });

    /* Conversion while editing */
    conversion.for('editingDowncast').elementToElement({
      model: 'Button',
      view: (modelElement, {writer: viewWriter}) => {
        const index = modelElement.index;
        console.log('editingDowncast');

        const linkTo = modelElement.getAttribute('href') || href[index];
        const alt = modelElement.getAttribute('alt') || value[index];

        const button = viewWriter.createContainerElement('a', {
          // class: 'cta-button',
          target: 'blank',
          href: linkTo,
          alt: alt,
          style: inlineStyle,
          id: 'cta-btn',
        });

        const innerText = viewWriter.createText(alt);
        viewWriter.insert(viewWriter.createPositionAt(button, 0), innerText);

        /* To widget makes the element not editable */
        return toWidget(button, viewWriter, {
          label: 'CTA Button widget',
        });
      },
    });
  }
}
