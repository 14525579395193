import {createSlice} from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    set: (state, {payload}) => {
      return {...state, ...payload};
    },
  },
});
