import React, {useEffect, useState} from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import Select from 'react-select';
import {Field} from 'formik';
import {useHistory, useParams} from 'react-router';

import {DataTable, Form} from '../components';
import {countries, languages} from '../config';
import {axios} from '../libs';

export const EmailTemplates = () => {
  const [initialValues, setinitialValues] = useState({});
  const [refetch, setrefetch] = useState(false);
  const {push} = useHistory();
  const {emailTemplateId} = useParams();

  const onSuccess = () => {
    setrefetch(false);
    if (emailTemplateId) {
      setrefetch(true);
      push('/email-templates');
    } else {
      setrefetch(true);
    }
  };

  useEffect(() => {
    if (emailTemplateId) {
      (async () => {
        const {emailTemplate} = await axios.get(`/email/template/${emailTemplateId}`);
        setinitialValues({...emailTemplate});
      })();
    }
  }, [emailTemplateId]);

  const tableColumns = [
    {
      dataField: 'country',
      text: 'Country',
    },
    {
      dataField: 'language',
      text: 'Language',
    },
  ];

  const onEdit = (data) => {
    push(`/email-templates/${data._id}`);
  };

  const onDelete = ({_id}) => {
    setrefetch(false);
    window.nConfirm({
      title: 'Warning',
      text: 'Are you sure you want to delete this template?',
      icon: 'fa-exclamation-circle',
      onYes: () => {
        deleteTemplate(_id);
      },
    });
  };

  const deleteTemplate = async (id) => {
    await axios.delete(`/email/template/${id}`);
    setrefetch(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="x_panel">
            <div className="x_title">
              <h2>Email Templates</h2>
              <div className="clearfix"></div>
            </div>
            <div className="x_content">
              <br />
              <Form
                url="/email/template"
                usePUT={emailTemplateId ? true : false}
                onSuccess={onSuccess}
                initialValues={initialValues}
              >
                <div className="row mb-3">
                  <label htmlFor="country" className="form-label d-none">
                    Region
                  </label>
                  <div className="col-md-6">
                    <Field name="country">
                      {({field, form}) => (
                        <Select
                          id="country"
                          placeholder="Select Country"
                          name={field.name}
                          options={countries}
                          value={countries?.find((i) => i.value === form.values.country)}
                          onChange={(options) => {
                            form.setFieldValue(field.name, options.value);
                          }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                            }),
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <label htmlFor="language" className="form-label d-none">
                    Language
                  </label>
                  <div className="col-md-6">
                    <Field name="language">
                      {({field, form}) => (
                        <Select
                          id="language"
                          placeholder="Select Language"
                          name={field.name}
                          options={languages}
                          value={languages?.find((i) => i.value === form.values.language)}
                          onChange={(options) => {
                            form.setFieldValue(field.name, options.value);
                          }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                            }),
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <label htmlFor="content" className="form-label d-none">
                  Content
                </label>
                <Field id="content" name="content">
                  {({field, form}) => (
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        simpleUpload: {
                          uploadUrl: `${process.env.REACT_APP_BASE_URL}/asset/image`,
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem('sid')}`,
                          },
                        },
                      }}
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setStyle('height', '500px', editor.editing.view.document.getRoot());
                        });
                      }}
                      onBlur={(event, editor) => {
                        event.stop();
                        form.setFieldValue(field.name, editor.getData());
                      }}
                      data={initialValues.content}
                    />
                  )}
                </Field>

                <div className="ln_solid"></div>
                <div className="col-md-9 col-sm-9">
                  <button type="submit" className="btn btn-success">
                    Save
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="x_panel">
            <div className="x_title">
              <h2>Email Templates</h2>
              <div className="clearfix"></div>
            </div>
            <div className="x_content"></div>
            <DataTable
              onEdit={onEdit}
              onDelete={onDelete}
              url="/email/template"
              dataKey="emailTemplates"
              tableHeading="Email Templates"
              columns={tableColumns}
              refetch={refetch}
            />
          </div>
        </div>
      </div>
    </>
  );
};
