import React, {useEffect, useState} from 'react';
import {DataTable, Form} from '../components';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import {Field} from 'formik';
import {useHistory, useParams} from 'react-router';
import {axios} from '../libs';

export const EmailContents = () => {
  const [initialValues, setinitialValues] = useState({});
  const {push} = useHistory();
  const {emailContentId} = useParams();

  const tableColumns = [
    {
      dataField: 'event',
      text: 'Event',
    },
  ];

  useEffect(() => {
    if (emailContentId) {
      (async () => {
        const {emailContent} = await axios.get(`/email/content/${emailContentId}`);
        setinitialValues({...emailContent});
      })();
    }
  }, [emailContentId]);

  const onSuccess = (values) => {
    push('/email-contents');
  };

  const onEdit = (data) => {
    push(`/email-contents/${data._id}`);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="x_panel">
            <div className="x_title">
              <h2>Email Content {initialValues.event}</h2>
              <div className="clearfix"></div>
            </div>
            <div className="x_content">
              <br />
              <Form
                url="/email/content"
                usePUT
                onSuccess={onSuccess}
                initialValues={{_id: initialValues._id, content: initialValues.content}}
              >
                <Field name="content">
                  {({field, form}) => (
                    <CKEditor
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setStyle('height', '250px', editor.editing.view.document.getRoot());
                        });
                      }}
                      onBlur={(event, editor) => {
                        form.setFieldValue(field.name, editor.getData());
                      }}
                      editor={ClassicEditor}
                      data={initialValues.content}
                      config={{
                        simpleUpload: {
                          uploadUrl: `${process.env.REACT_APP_BASE_URL}/asset/image`,
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem('sid')}`,
                          },
                        },
                      }}
                    />
                  )}
                </Field>

                <div className="ln_solid"></div>
                <div className="col-md-9 col-sm-9">
                  <button disabled={emailContentId ? false : true} type="submit" className="btn btn-success">
                    Save
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="x_panel">
            <div className="x_title">
              <h2>Email Contents</h2>
              <div className="clearfix"></div>
            </div>
            <div className="x_content"></div>
            <DataTable
              onEdit={onEdit}
              url="/email/content"
              dataKey="emailContents"
              tableHeading="Email Templates"
              columns={tableColumns}
            />
          </div>
        </div>
      </div>
    </>
  );
};
