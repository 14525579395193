import React, { createContext, useState } from 'react';

// Create a Context with an empty default value
const GlobalStateContext = createContext();

// Create a Provider component that will wrap the app
const GlobalStateProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({ id: '' });

  return (
    <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export { GlobalStateContext, GlobalStateProvider };