import React, {useState} from 'react';
import {Field} from 'formik';
import {DataTable, Form} from '../components';
import {acceptFiles} from '../config';

export const Sheets = React.memo(() => {
  const [refetch, setrefetch] = useState(false);

  const initialValues = {
    name: '',
    document: null,
  };


  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSuccess = ({chatbot}) => {
    setrefetch(true);
  };

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name',
    },
  ];

  const onDelete = ({_id}) => {
    setrefetch(false);
    window.nConfirm({
      title: 'Warning',
      text: 'Are you sure you want to delete this user?',
      icon: 'fa-exclamation-circle',
      onYes: () => {
       // deleteUser(_id);
      },
    });
  };

  return (
    <>
   




      <div className="row table_v2">
        <div className="col">
          <div className="x_panel chatbot-builder">

            <div className="header-menu d-flex justify-content-between">

             {/*  <h2>
                Documents
              </h2> */}
             

              <div className="d-flex justify-content-end page-top-container">
                      <div className='btn-add link' onClick={openModal}>
                         Add Sheet
                      </div>
              </div>


             
            </div>


            <div className="x_content">

                <div className="page-container">



                  <DataTable
                    
                    onDelete={onDelete}
                    refetch={refetch}
                    navigateTo="https://docs.google.com/spreadsheets/d"
                    url="/user/sheets"
                    dataKey="documents"
                    tableHeading="Spreadsheets"
                    columns={tableColumns}
                  />
               </div>
            </div>
          </div>
        </div>
      </div>


      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="modalclose" onClick={closeModal}>
              &times;
            </span>

            <div className='modal_header mb-4 my-4'>
              <h2>Create new document</h2>
            </div>
          

            <Form
                enctype="multipart/form-data"
                // url={isMultipart ? '/user/documents/upload' : '/user/documents'}
                url = '/user/sheets'
                initialValues={initialValues}
                onSuccess={onSuccess}
              >
                <div className="row mb-3">
                  <label htmlFor="name" className="form-label comp-header">
                    Spreadsheet Name
                  </label>
                  <div className="">
                    <Field id="name" name="name" type="text" className="form-control" />
                  </div>
                </div>

           

                <div className="d-flex justify-content-end">
                  <button className="light-btn" onClick={closeModal}>
                    Cancel
                  </button>
                  <button type="submit" className="main-btn">
                    Save
                  </button>
                </div>


              </Form>



          </div>
        </div>
      )}

  
</>
   
  );
});
