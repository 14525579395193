import {useEffect, useRef, useState, memo} from 'react';
import {useParams} from 'react-router';
import {useDropzone} from 'react-dropzone';
import Select , { components } from 'react-select';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {axios} from './axios';
import styles from './Chat.module.css'; // Import css modules stylesheet as styles
import {moneyCurrency} from '../../../config';

import {MoreOptions} from './components';
import MaskedInput from 'react-text-mask';



import {User} from '../../../states/actions';
import {useSelector} from 'react-redux';


const Joi = require('joi');




const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props;
  const nbValues = getValue().length;
  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer {...props}>
      {`${nbValues} items selected`}
    </components.ValueContainer>
  );
};

const MultiValue = props => {
  return "3 Selected";
};



function Chat() {

  const {chatbotId, redirectId, lang} = useParams();
  const {_id} = useSelector(User.get());
  const [chatProgress, setChatProgress] = useState({total: 0, current: 0, ratio: 0});
  const [currentProgress, setCurrentProgress] = useState(1);



  const localProgressId = window.localStorage.getItem(`pid_${chatbotId}`);
  const [showChat, setShowChat] = useState(false);
  const [progressId, setProgressId] = useState(localProgressId || '');
  const [messages, setmessages] = useState([]);
  const [files, setFiles] = useState([]);
  const [waiting, setwaiting] = useState(false);
  const [editmode, seteditmode] = useState(false);
  const [reviewhome, setreviewhome] = useState(false);
  const [validationstate, setvalidationstate] = useState(false);
  const [progress, setProgress] = useState();
  const [maxprogress, setMaxprogress] = useState();
  const [modal, setModal] = useState(false);
  const botId = chatbotId;
  
  const [address, setAddress] = useState(null);
  const [block, setBlock] = useState({});
  const [chatbot, setChatbot] = useState(null);
  const inputRef = useRef(null);
  const emailsRef = useRef(null);
  const shareEmail = useRef();
  const [sharevalidations, setShareValidations] = useState();
  const [sharemessagevalidations, setShareValidationsMessage] = useState();
  const [decisionvalidations, setDecisionValidation] = useState();
  const [multiplechoicevalidations, setMultipleChoiceValidation] = useState();
  
  
  const messageRef = useRef(null);
  const optionSelectRef = useRef(null);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedtime, setSelectedTime] = useState(null);
  const [isFirstReply, setIsFirstReply] = useState(false);
  const [prevBlock, setPrevBlock] = useState([]);
  const [reviews, setreviews] = useState([]);
  const [prevrepli, setPrevrepli] = useState([]);



  const {getRootProps, getInputProps} = useDropzone({
    accept: block?.acceptedTypes?.toString(),
    onDrop: (acceptedFiles) => {
      const updatedFiles = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      setFiles([...files, ...updatedFiles]);
      _scroll();
    },
  });
  useEffect(() => {
    const updateChat = async () => {
      try {
        if (params?.rid) {
          await axios.put(`/redirect/${params?.rid}`);
        }
      } catch (error) {
        console.error(error);
      }
    };
    updateChat();
  }, [params?.rid]);
  const callProgressApi = async () => {
    try {
      const {messages, lastMessage} = await axios.post(`/progress`, {progressId: params?.pid || localProgressId});
      //Take only the last message instead of all conversation history:
      //setSecond(false);
      setmessages(messages.map((message) => ({msg: message.content, headtext:message.headtext, buttontext:message.buttontext, user: message.type})).slice(-1));
      setBlock(lastMessage);
      if (!params?.rid && params?.pid) setShowChat(true);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const chat = async () => {
      if (params?.pid || localProgressId) {
        callProgressApi();
      } else {
        callApi();
      }
    };
    chat();
  }, []);

  useEffect(() => {
    if (progressId.length > 1) {
      onFirstReply(prevBlock);
    }
  }, [progressId]);



  const onReply = async (event, second = undefined) => {

    event.preventDefault();
    let userReply = '';

    //let second = second;
    //let second = second;

    if (block.validation === 'money') {
      console.log(inputRef.current.value)
      const temp = `${moneyCurrency.find((i) => i.value === block.currency).sign}${inputRef.current.value}`;
      console.log(temp)
      if(inputRef != null){userReply = temp}else{userReply = temp}

    } else if (block.validation === 'address') {
      if(address != null){userReply = address.value.description}else{userReply = address;}
      //setAddress(null);
    } else if (block.validation === 'name') {
      userReply = inputRef.current.value
        .trim()
        .split(' ')
        .map((i) => i[0]?.toUpperCase() + i.slice(1)?.toLowerCase())
        .join(' ');
    } else if (block.validation === 'date') {
      const temp = moment(new Date(selectedDate)).format('DD.MM.YYYY');
      if(selectedDate != null){userReply = temp}else{userReply = selectedDate}
    } else if (block.validation === 'birthday') {
      const temp = moment(new Date(selectedDate)).format('DD.MM.YYYY');
      if(selectedDate != null){userReply = temp}else{userReply = selectedDate}
    } else if (block.validation === 'time') {
      const temp = moment(new Date(selectedtime)).format('HH:mm');
      if(selectedtime != null){userReply = temp}else{userReply = selectedtime}
    } else {
      userReply = inputRef.current.value.trim();
    }


    
    await reply(userReply, undefined, undefined, second);
    _scroll();

  };

  const reply = async (userReply, replyText = '', newid, second) => {
    
    //Seda pole üldse vaja?
    setMessage({msg: replyText ? replyText : userReply, user: 'user'});
    
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    if (optionSelectRef.current) {
      optionSelectRef.current.state.value = '';
    }

    if (!isFirstReply) {
      await setIsFirstReply(true);
    }

   //Kontrollida
    if(newid != undefined){
      console.log("NEW")
      await callApi(block.id, userReply)
      console.log(reviewhome)
      getBlock(reviewhome)
    }
    //else if(editmode){
    //  console.log("ONEDITMODE")
    //  getBlock(reviewhome, userReply)
    //}
    else{
     await callApi(block.id, userReply, second);

    }
    
  };

  const setMessage = ({user = 'system', ...rest}) => {

    setmessages([]);
    setmessages((state) => {
      return [...state, {user, ...rest}];
    });
  
  };



  const uploadFile = async (id, formData) => {
    setwaiting(true);
    const _block = await axios.post(chatbotId, formData);
    if (_block.id) {
      setBlock(_block);
      setMessage({msg: _block.msg});
      if (!_block.waitForReply) {
        await callApi(_block.id);
      }
    } else {
      setBlock({});
    }
    setwaiting(false);
    _scroll();
  };
  const thumbs = files.map((file) => {
    const isImage = file.type.split('/')[0] === 'image';
    return (
      <div className={`col-3 position-relative ${styles.thumb} my-1`} key={file.name}>
        {isImage && <img src={file.preview} className={styles.img} alt={file.name} />}
        {!isImage && (
          <div className="row align-items-center">
            <div className="col-md-3 offset-md-3">
              <i className="fa fa-file-text-o fa-3x"></i>
            </div>
            <div className="col offset-md-1">{file.name}</div>
          </div>
        )}
        <button
          id="image-delete-button"
          onClick={() => {
            setFiles(files.filter((i) => i.preview !== file.preview));
          }}
          className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
        >
          <i className="fa fa-trash" style={{color: '#fff'}}></i>
        </button>
      </div>
    );
  });
  const gtmEvent = (block) => {
    if (window.dataLayer) {
      const {event, variables = []} = block;
      const obj = {event};
      for (const {name, value} of variables) {
        obj[name] = value;
      }
      window.dataLayer.push(obj);
    } else {
      console.log('data layer not available');
    }
  };
 


  const replyWithWebFunction = (funcName) => {
    if (window[funcName] && typeof window[funcName] === 'function') {
      const selectedValue = window[funcName]();
      if (selectedValue) {
        reply(selectedValue);
      }
    }
  };

//?
const onFirstReply = async (prevBlock = []) => {
  try {
    setwaiting(true);
    await axios.setFirstReplyBlocks(prevBlock, chatbotId);
  } catch (error) {
    console.error(error);
  } finally {
    setwaiting(false);
  }
};



  useEffect(() => {
    //getProgressTree();
    if (block.type === 'review') {
      getChatData();
    }
  }, [block]);
  useEffect(() => {
    if (block.webFunction) {
      switch (block.type) {
        case 'multipleChoice':
          replyWithWebFunction(block.webFunction);
          break;
        default:
          break;
      }
    }
  }, [block]);
  useEffect(() => {
    if (showChat) {
      _scroll();
    }
  }, [showChat]);




  const getProgressTree = async () => {
    try {

      //Working on PROGRESSTREE here
      //const botget = await axios.getBotBlocks('6304c834c0927eabf49d0217');
      //console.log(botget);
      console.log(chatbot);
      const bot = await axios.getBot(botId);
      console.log(bot)
      setChatbot(bot);
      
      //OLULINE : SEDA VÄÄRTUST ON VAJA AINULT ÜHE KORRA SISSE LAADIDA, selle peaks laadima kui esimest korda laeb
      //Kontrollida
      const maxprogress_v = await axios.getBotMaxProgress(botId)
      const prog = 100 * block.progress / maxprogress_v[0];
      console.log(prog, maxprogress_v[0]);

      setProgress(prog);
      setMaxprogress(maxprogress_v[0]);

    } catch (error) {
      console.error(error);
    }
  };


  const [menushow, setmenuShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState('0');

  const handleScroll = event => {

    if (event.currentTarget.scrollTop > lastScrollY) { // if scroll down hide the navbar
      setmenuShow(false); 
    } else { // if scroll up show the navbar
      setmenuShow(true);  
    }
    setLastScrollY(event.currentTarget.scrollTop); 

  };
  function handleClick(e) {
    console.log(e.target.value);
  }
  const handleShowChat = () => {
    if (!showChat) {
    }
    setShowChat(!showChat);
  };

  //?
  const _scroll = () => {
    const chatBody = document.getElementById('chat-body');
    if (chatBody) {
       //chatBody.scrollTop = chatBody?.scrollHeight;
    }
  };



  const [platformValue, plaftormInputProps] = useRadioButtons("platform");

  function useRadioButtons(name) {
    const [value, setState] = useState(null);
  
    const handleChange = e => {
      setState(e.target.value);
    };
  
    const inputProps = {
      name,
      type: "radio",
      onChange: handleChange
    };
  
    return [value, inputProps];
  }


  function openModal() {
    setModal(true);
  }
  function closeModal() {
    setModal(false);
  }


  function setOption(i){
    optionlist.push(
    {label: i, value: i});
    addComponent(i);
  }
  const optionlist = [
    {label: 1, value: 1},
    {label: 2, value: 2},
  ];

  //For share block emaillist
  const [components, setComponents] = useState([]); 

  function addComponent(i) { 
    setComponents([...components, i])   
  } 
  function removeComponent(index) {
    setComponents(components.filter((i) => i !== index))
  }






  
  //CLIENT APP SPECIFIC
  const [mobile, setmobile] = useState(true);
  const toggleMobile = async () => {
    if(mobile){setmobile(false)}else{setmobile(true)}
  };
  const onStartAgain = async () => {
    setmessages([]);
    await callApi();
    setCurrentProgress(1);
  };
  const [previousBlock, setpreviousBlock] = useState([]);
  //KONTROLLI - kaks korda lindistab sama väärtust kui samas sammus
  function setPrevious(){
      console.log(previousBlock)
      if(previousBlock[previousBlock.length-1] != block.id){previousBlock.push(block.id)}
  }
  const getPreviousBlock = async () => {
    getBlock(previousBlock[previousBlock.length-1])
    previousBlock.pop();
  };
  const getChatData = async () => {
    try {

      //KONTROLLIDA Kasutan praegu get ruuti, selleprast ka autent
      const ch0 = await axios.getData(botId);
      

      let data = ch0.conversations[1].data;

      let ready = "ready";
      let reviewelementsData = [];
      for (let i = 0; i < block?.reviews.length; i++) {    
        const data = await axios.getBlockData(botId, block.reviews[i].element.value);
        console.log(data);
        reviewelementsData.push({
          headtext: data.headtext,
          id:data.id,
        });
        console.log(block.reviews[i].element, data.headtext, data.id);
      }

      setreviews({reviewelementsData, data, ready});
      console.log(reviews)
      setreviewhome(block.id);
      seteditmode(false);

      
    } catch (error) {
      console.error(error);
    }
  };
  const getBlock = async (id, reply) => {
 
    try {
      window.setTimeout(async () => {
        const _block = await axios.getBlock(chatbotId, id);
        setMessage({msg: _block.msg, buttontext:_block.buttontext, type: _block.type});
        setBlock(_block);
        _scroll();
        setwaiting(false);
      }, 100);
    } catch (error) {
      console.error(error);
    } finally {
      setwaiting(false);
    }
  };

  
  const callApi = async (id = undefined, reply = undefined, second) => {
    
    try {
      const _promise = () => {
        setwaiting(true);
        window.setTimeout(async () => {
          //_scroll();
        }, 1);

        const promise = new Promise(function (resolve, reject) {
          window.setTimeout(async () => {

            const _block = await axios.post(chatbotId, {id, reply}, _id);
            setPrevious();
        

            if (_block.chatProgressId) {
              window.localStorage.setItem(`pid_${chatbotId}`, _block.chatProgressId);
              setProgressId(_block.chatProgressId);
            }


            if (_block.id) {
          

              if (!isFirstReply) {
                setPrevBlock((prev) => [...prev, _block.id]);
              }

              
            
              //kontrollida, midagi ei toimu
              //if (userBlockTypes.includes(_block.type) && block.id !== _block.id) 
              //{} 
              //else if (_block.type === 'text' && _block.next === null) 
              //{}

              
              if(!editmode){
                setMessage({msg: _block.msg, headtext:_block.headtext, buttontext:_block.buttontext, type: _block.type});
                setBlock(_block);
              } else if ( editmode && _block.hasvalidation)
              {

                setMessage({msg: _block.msg, headtext:_block.headtext, buttontext:_block.buttontext, type: _block.type});
                setBlock(_block);
              }


              if (!_block.waitForReply) {
                // check for gtm
                if (_block.type === 'gtm') {
                  gtmEvent(_block);
                }
                await callApi(_block.id);
              }

            } else {

              setBlock({});
              
            }

            if (_block.hasvalidation){setvalidationstate(true)}else{setvalidationstate(false)}

            setwaiting(false);
            //_scroll();
            resolve(_block);

            if(editmode && !_block.hasvalidation){
              setBlock({});
              setvalidationstate(false)
              getBlock(reviewhome)}

          }, 300);
        });

        return promise;
      };
      return await _promise();
    } catch (error) {
      setwaiting(false);
      console.error(error);
    }
  };

  //Need to fix in widget: edit and get block functions both to exist
  const editBlock = async (id, reply) => {

     try {
 
       window.setTimeout(async () => {
  
         const _block = await axios.getBlock(chatbotId, id);
         setMessage({msg: _block.msg, buttontext:_block.buttontext, type: _block.type});
         setBlock(_block);
         seteditmode(true);
         _scroll();
         setwaiting(false);

       }, 100);
     } catch (error) {
       console.error(error);
     } finally {
       setwaiting(false);
     }
   };






return (
  <>
    <div  onClick={handleShowChat} className={`${styles['chat-opener']} chat-opener-btn`}></div>
    {showChat && (
      <div className={`${styles['app']}`}>
      <div className={`${styles['app-header']} ${menushow === false ? styles['scroll'] : ''}`}>
          <MoreOptions progressId={progressId} />
          <div className={`${styles['client-controls']}`} >
              <i className="fa fa-sync fa-lg" title="Start Again" onClick={onStartAgain}></i>
              <i className={`${styles['mobile-hide']} fa fa-regular fa-mobile fa-lg`} title="mobile" onClick={toggleMobile}></i>
              <i onClick={() => setShowChat(false)} alt="close-icon" className="fas fa-times fa-lg"></i>
             
            </div>

      </div>
      {block.isProgress === true && (
            <div className={`${styles['progress-container']} ${editmode === true ? styles['progresseditmode'] : ''}`}>
                <div className={`${styles['progress-control']} ${menushow === false ? styles['scroll'] : ''}`}>
                  <span className={styles['progress-info']}>Step {block.progress} / {block.maxprogress}</span>
                  <span onClick={() => getPreviousBlock()} className={styles['left-button']}> <i class="fa fa-sharp fa-solid fa-angle-left"></i> </span>

                    {block.type === 'input' ? (
                        <span onClick={ async (e) => { onReply(e) }} className={styles['right-button']}> <i class="fa fa-sharp fa-solid fa-angle-right"></i> </span>

                    ): block.type === 'decision' ? (
                        <span onClick={ async (e) => { 
                          if (!platformValue){
                            setDecisionValidation('Please make a choice')
                          } else {
                            setDecisionValidation('');
                            reply(platformValue);
                          }
                        }} className={styles['right-button']}> <i class="fa fa-sharp fa-solid fa-angle-right"></i> </span>

                    ): block.type === 'text' ?(
                      <span onClick={ async (e) => { reply('0') }} className={styles['right-button']}> <i class="fa fa-sharp fa-solid fa-angle-right"></i> </span>

                    ): block.type === 'multipleChoice' ?(
                    
                      <span onClick={ async (e) => { 
                        if (!platformValue){
                          setMultipleChoiceValidation('Please make a choice')
                        } else {
                          setMultipleChoiceValidation('');
                          reply(platformValue);
                        }
                      }} className={styles['right-button']}> <i class="fa fa-sharp fa-solid fa-angle-right"></i> </span>
                    
                    ): block.type === 'review' ? (
                      <span onClick={ async (e) => {  reply('0'); }} className={styles['right-button']}> <i class="fa fa-sharp fa-solid fa-angle-right"></i> </span>
                    ): block.type === 'share' ?(
                      <span onClick={ async (e) => {  reply(false); }} className={styles['right-button']}> <i class="fa fa-sharp fa-solid fa-angle-right"></i> </span>
                    ):(<div></div>)}
                 
                </div>
                <div className={styles['progress-indicator']}>
                  <table width={100 * block.progress / block.maxprogress + '%'} className={styles['progress']}></table>
                </div>
            </div>
          )}
      <div className={`${styles['app-promo']}`}>
          {chatbot != null && (
            <div>
              <div className={`${styles['app-promo-text']}`}>{chatbot.chatbot.description}</div>
              <div className={`${styles['app-promo-area']}`} dangerouslySetInnerHTML={{__html: chatbot.chatbot.img}}></div>
            </div>
          )}
      </div>
      <div className={`${styles['chat-wrapper']} ${editmode === true ? styles['editmode'] : ''}`}>
       
    


        <div  onScroll={handleScroll} id="chat-body"  className={`${styles['chat-body']} ${menushow === false ? styles['scroll'] : ''}`}>
       
        
        
        

          <div id="message-container">
         
            
            {messages?.map((message, index) => {
            return message.user === 'user' ? 
              (
              <div></div> //SIIN SAADAB KASUTAJA SÕNUMI VÄLJA; SEDA OSA VÕIKS ÜLDSE MITTE OLLA
              ) : ( message.msg &&(
                  <div key={index}>
                    <div className={styles['message-wrapper']}>
                      <div className={styles['message-wrapper-row']}>
                        <div className={styles['progressheader']}>{block.progressheader}</div>
                        <div id="last-message"
                          className={`${styles['message-solo']} hugomessage active`}
                          dangerouslySetInnerHTML={{__html: message.msg}}
                        ></div>
                       
                       
                        <div>
                          <i  className={`${styles['modallink']}`} onClick={openModal}>{block.modallink}</i>
                          {modal === true &&(
                            <div onClick={closeModal}                             
                             className={`${styles['modalcontainer']}`}                              >
                              <i className={`${styles['modal-close']} fa fa-times`}></i>
                              <span  dangerouslySetInnerHTML={{__html: block.modal}}></span>
                              </div>
                          )}
                        </div>

                      </div>
                    </div>
                  </div>
                )
                );
            })}


         

          </div>

          <div id="reply-container">

          {block.type === 'text' && !waiting &&(
            <div className={styles['chat-footer']}>
             <div  className={`${styles['buttons']}`}>

             <button 
                  dangerouslySetInnerHTML={{__html: block.buttontext}} 
                  onClick={async () => {reply("0");}} //VAJAPARANDADA
                  className={`${styles['chat__next__button']} chat__next__button`}
                  >
              </button>
            </div>
            </div>
          )}

          {block.type === 'richText' && !waiting &&(
            <div className={styles['chat-footer']}>
             <button 
                  dangerouslySetInnerHTML={{__html: block.buttontext}} 
                  onClick={async () => {reply("0");}} //VAJAPARANDADA
                  className={`${styles['chat__next__button']} chat__next__button`}
                  >
    
              </button>
            </div>
          )}  

          {block.type === 'review' && reviews.ready === "ready" && !waiting &&(
               <div className={styles['message-wrapper']}>
                
                <div className={styles['message-wrapper-row']}>

                  <div className={styles['message-wrapper-element']}>
                  
                  
                  

                  </div>

                  <div className={`${styles['chat__radio__group']} chat__radio__group`}>
                        {block?.reviews?.map((item, index) => (
                            <div>
                                  <div className={`${styles['chat_edit_h']}`} >{reviews.reviewelementsData[index].headtext}</div>
                                  <div 
                                  onClick={() =>  {getBlock(reviews.reviewelementsData[index].id)}}  
                                  className={`${styles['chat__edit__input']} chat__edit__input`}
                                  >{reviews.data[item.element.label]}<i class="fa fa-pencil"></i>
                                  </div>
                            </div>
                        ))}
                  </div>
                  
                  <div className={`${styles['buttons']}`}>

                      <button 
                          dangerouslySetInnerHTML={{__html: block.buttontext}} 
                          onClick={async () => {reply("0");}} //KONTROLLIDA
                          className={`${styles['chat__next__button']} chat__next__button`}
                          >
                      </button>
                  </div>
                  

                  </div>
               
              </div>         
 
           )}
      
          {block.type === 'share' && !waiting &&(

          <div className={styles['chat-footer']}>
              <div className="input-group">
             
                     <input
                      as="input"
                      ref={emailsRef}
                      type="hidden"
                      id="reply"
                      name="reply"
                      className={`${styles.formInput}`}
                      placeholder={block?.hint ? block.hint : 'Please enter your text here'}
                      required
                    />

                    <input  
                      as="input"
                      id="inputemail"
                      name="inputemail"
                      ref={shareEmail}
               
                      className={`${styles['formInput']} ${styles['share-input']}`}
                      placeholder="Add friends"

                      onKeyDown={async (e) => {
                        if (e.code === 'Enter' && e.key === 'Enter') {
                          const valid = Joi.string().email({tlds:{allow: false}}).validate(shareEmail.current.value);
                            
                          if(valid.error){
                            console.log("is not" + valid)
                            setShareValidations( shareEmail.current.value + "is not a valid email aadress!")
                          }
                          else{
                            setShareValidations("");
                            setOption(shareEmail.current.value);
                            shareEmail.current.value = "";
                            
                          }
                        }
                      }}
                    />

                    <button 
                       className={`${styles['chat__next__button']} ${styles['share-input-btn']} `}
                       type="button"
                       
                       onClick={async () => {
                        
                            const valid = Joi.string().email({tlds:{allow: false}}).validate(shareEmail.current.value);
                            
                            if(valid.error){
                              console.log("is not" + valid)
                              setShareValidations( shareEmail.current.value + "is not a valid email aadress!")
                            }
                            else{
                              setShareValidations("");
                              setOption(shareEmail.current.value);
                              shareEmail.current.value = "";
                              
                            }
      
                       
                      }}
                    ><i class="fa fa-plus" aria-hidden="true"></i></button>
                    
                    <div className={styles['red']}>{ sharevalidations }</div>

                     {components.map((item, i) => ( 
                       <span  className={styles['share-emaillist']} id={item}
                         onClick={async () => {
                          removeComponent({item}.item);
                         }}>
                        <i class="fa fa-close"></i>
                        {item}
                        </span>
                     ),[])} 


                    <textarea
                      ref={messageRef}
                      as="textarea"
                      type='textarea'
                      id="textarea"
                      name="rextarea"
                      className={`${styles['input-textarea']} `}
                      rows="4"
                      value={block?.defaultmessage || ""}
                      placeholder={block?.hint ? block.hint : 'Your message'}
                      required
                     />
                    <div className={styles['red']}>{ sharemessagevalidations }</div>



                    <div  className={`${styles['buttons']}`}>

                     {/* <button
                        onClick={async () => {
                            reply(false);
                        }}
                        className={`${styles['chat__third__button']}`}
                      >Skip this for now</button>    */}
                    
                    

                     
                     {editmode === false && (
                      <>

                          { block.next_secondary != undefined && block.next_secondary != ""  && (

                            <button   
                            onClick={async (e) => {onReply(e, true)}}
                            type="submit"
                            className={`${styles['chat__next_secondary__button']}`}> 
                         
                              {block.secondbutton}
                            
                            </button>

                          )}


                          <button
                            dangerouslySetInnerHTML={{__html: block.buttontext}} 
                            onClick={async () => {
                              console.log(components.length)
                              if(components.length < 1){
                                setShareValidations("Please add friends")
                              }
                              if(Joi.string().validate(messageRef.current.value).error){
                                setShareValidationsMessage("Please add message")
                              }
                              if(components.length > 0 && !Joi.string().validate(messageRef.current.value).error){
                                console.log("OK")
                                const rep = {};
                                emailsRef.current.value = components;
                                rep.message = messageRef.current.value;
                                rep.emails = emailsRef.current.value;
                                reply(rep)
                              }
                            
                          }}
                          className={`${styles['chat__next__button']} chat__next__button`}
                            >
                          </button>


                      </>

                      )}  

                    </div>  




                   {editmode && (
                      <button
                          type="submit"
                          onClick={async () => {
                              //reply();
                              
                              console.log(reviewhome)
                              getBlock(reviewhome)
                          }}
                          className={`${styles['chat__edit__button']} chat__edit__button`}
                        >Salvesta</button>    
                    )}
               

              </div>
              </div>

          )}

          {block.type === 'decision' && !waiting &&(
            
            <div className={styles['chat-footer']}>  
                 
                  <div id="multiple-choice">                  
                  
                    <div className={`${styles['chat__radio__group']} chat__radio__group`}>
                    {block.decisions.length === 2 ? (

                      <div>
                        <span  
                        dangerouslySetInnerHTML={{__html: block.notification}} 
                        className={`${styles['widget_notification']} widget_notification`}
                        ></span>
                        
                          <div  className={`${styles['buttons']}`}>

                              <button
                                dangerouslySetInnerHTML={{__html: block.decisions[0].text}} 
                                onClick={async () => {
                                reply(block.decisions[0].text);
                                }}
                                className={`${styles['chat__next_secondary__button']}`}
                              >
                              </button>

                              <button
                                dangerouslySetInnerHTML={{__html: block.decisions[1].text}} 
                                onClick={async () => {
                                  reply(block.decisions[1].text);
                                }}
                                className={`${styles['chat__next__button']} chat__next__button`}
                              >
                              </button>

                        </div>
                      </div>

                    ) : block.decisions.length === 1 ?(
                      <div>
                      <span  
                      dangerouslySetInnerHTML={{__html: block.notification}} 
                      className={`${styles['widget_notification']} widget_notification`}
                      ></span>
                      
                        <div  className={`${styles['buttons']}`}>          

                           <button
                              dangerouslySetInnerHTML={{__html: block.decisions[0].text}} 
                              onClick={async () => {
                                reply(block.decisions[0].text);
                              }}
                              className={`${styles['chat__next__button']} chat__next__button`}
                            >
                            </button>

                      </div>
                    </div>
                    ):(

                      <div> 
                        {block?.decisions?.map((item) => (
                          <div>
                          <input
                            id={item.text}
                            type="radio"
                            value={item.text}
                            name="decision"//{optionSelectRef}
                            onClick={(e) => handleClick(e)}
                            checked={platformValue === item.text}
                            className={`${styles['chat__radio__input__button']} chat__radio__input__button`}
                            //checked={optionSelectRef.state.value === item.value}
                            //onChange={this.onValueChange}
                            {...plaftormInputProps}
                          />
                            <label  for={item.text} className={`${styles['chat__radio__button']} chat__radio__button`}> {item.text}<i className={`${styles['chat__radio__check']} fa fa-check`}></i></label>
                           </div>
                        ))}
                          <div className={styles['red']}>{ decisionvalidations }</div>

                          <span  
                          dangerouslySetInnerHTML={{__html: block.notification}} 
                          className={`${styles['widget_notification']} widget_notification`}
                          ></span>

                          <div  className={`${styles['buttons']}`}>
                                                           
                              <button
                                  dangerouslySetInnerHTML={{__html: block.buttontext}} 
                                  onClick={async () => {

                                    const selectedOption = platformValue;
                                    if (!platformValue){
                                      setDecisionValidation('Please make a choice')
                                    } else {
                                      setDecisionValidation('');
                                      reply(selectedOption);
                                    }

                                  }}
                                  className={`${styles['chat__next__button']} chat__next__button`}
                                >
                              </button>
                                
                          </div>
                            
                      </div>
                    
                    )}
                
                    </div>
                  
              
                    
              
                  

                  </div>
            </div>
            
          )}

          {block.type === 'booking' && !waiting &&(
            <div id="message-wrapper-decisions">
              {block?.types?.map((item, i) => (
                <span
                  key={i}
                  className="btn btn-outline-primary btn-sm"
                  onClick={(ev) => {
                    reply(item);
                  }}
                >
                  {item}
                </span>
              ))}

              {block?.timeSlots?.map((slot) => (
                <span
                  key={slot.id}
                  className="btn btn-outline-primary mx-2 btn-sm"
                  onClick={(ev) => {
                    reply(slot.id, ev.target.innerText);
                  }}
                >
                  {slot.date} - {slot.start_time}-{slot.end_time}
                </span>
              ))}
            </div>
          )}

          {block.type === 'multipleChoice' && !waiting &&(
            <div className={styles['chat-footer']}>



              <div id="multiple-choice">
              
                 {block.selecttype === "radio" && (    
                        <div>       
                          <div className={`${styles['chat__radio__group']} chat__radio__group`}>
                          {block?.options?.map((item) => (
                              <div>
                              <input
                                id={item.value}
                                type="radio"
                                value={item.value}
                                name="choice"//{optionSelectRef}
                                onClick={(e) => handleClick(e)}
                                checked={platformValue === item.value}
                                className={`${styles['chat__radio__input__button']} chat__radio__input__button`}
                                //checked={optionSelectRef.state.value === item.value}
                                //onChange={this.onValueChange}
                                {...plaftormInputProps}
                              />
                              <label  for={item.value} className={`${styles['chat__radio__button']} chat__radio__button`}> {item.value}<i className={`${styles['chat__radio__check']} fa fa-check`}></i></label>
                              </div>
                          ))}
                        </div>
                        <div className={styles['red']}>{ multiplechoicevalidations }</div>

                            
                        <span  
                          dangerouslySetInnerHTML={{__html: block.notification}} 
                          className={`${styles['widget_notification']} widget_notification`}
                        ></span>

                        <div  className={`${styles['buttons']}`}>
                          {editmode === false && (
                            <button
                            dangerouslySetInnerHTML={{__html: block.buttontext}} 
                            onClick={async () => {
                              const selectedOption = platformValue;
                              if (!platformValue){
                                setMultipleChoiceValidation('Please make a choice')
                              } else {
                                setMultipleChoiceValidation('');
                                reply(selectedOption);
                              }
                          }}
                          className={`${styles['chat__next__button']} chat__next__button`}
                          >
                          </button>
                          )}    

                          {editmode && (
                                  <button
                                      onClick={async () => {
                                          //reply();
                                          const selectedOption = platformValue;
                                            if (selectedOption) {
                                              reply(selectedOption, reviewhome, true);
                                            }
                                        
                                      }}
                                      className={`${styles['chat__edit__button']} chat__edit__button`}
                                    >Salvesta</button>                    
                          )}
                        </div>
                        
                        </div>
                  )}



                {block.selecttype === "select" && (      
                        <div>       
                          <div className="input-group">
                              <Select
                                options={block.options}
                                ref={optionSelectRef}
                                id="choice"
                                name="choice"
                                className={styles['multiple-choice-selector']}
                                placeholder={block?.hint ? block.hint : ''}
                                classNamePrefix="react-select"
                                menuPlacement="bottom"
                                onKeyDown={async (e) => {
                                  if (e.code === 'Enter' && e.key === 'Enter') {
                                    const selectedOption = optionSelectRef.current.state.value;
                                    if (selectedOption) {
                                      reply(selectedOption.label);
                                    }
                                  }
                                }}
                              />

                              
                          </div>
                                

                          <span  
                          dangerouslySetInnerHTML={{__html: block.notification}} 
                          className={`${styles['widget_notification']} widget_notification`}
                        ></span>

                        <div  className={`${styles['buttons']}`}>

                          {editmode === false && (
                                <button
                                dangerouslySetInnerHTML={{__html: block.buttontext}} 
                                onClick={async () => {
                                  const selectedOption = optionSelectRef.current.state.value;
                                  if (selectedOption) {
                                    reply(selectedOption.label);
                                  }
                                }}
                                className={`${styles['chat__next__button']} chat__next__button`}
                              ></button>
                          )}    

                          {editmode && (
                                <button
                                  onClick={async () => {
                                    const selectedOption = optionSelectRef.current.state.value;
                                    if (selectedOption) {
                                      reply(selectedOption.label, reviewhome, true);
                                    }
                                  }}
                                  className={`${styles['chat__edit__button']} chat__edit__button`}
                                >Save changes</button>              
                          )}
                        </div>

                        </div>
                )}



                  
               


              </div>
            </div>
          )}

          {block.type === 'upload' && !waiting && (
            <div id="upload-container">
              <div className="container">
                <div className="row mx-2">
                  <div className="file-drop-zone col" {...getRootProps()}>
                    <input {...getInputProps({multiple: true})} />
                    <p>{`click to select files (${block?.acceptedTypes?.toString()}) `}</p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mx-2">{thumbs}</div>
              <div className="row mx-2">
                <div className="col">
                  {files.length > 0 && (
                    <button
                      id="upload-submit-button"
                      onClick={async () => {
                        const formData = new FormData();
                        formData.append('id', block.id);
                        files.forEach((file) => {
                          formData.append('files', file);
                        });
                        await uploadFile(block.id, formData);
                        setFiles([]);
                      }}
                      className="btn btn-primary btn-sm my-2 ml-4"
                    >
                      <i className="fa fa-share"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}


          {block.type === 'input' && !waiting && (
            <div className={styles['chat-footer']}>
             
                {block.validation === 'money' && block.currency ? (
                  <div className="input-group">
                    <span className={`${styles['input-currency']} input-group-text`}>
                      {moneyCurrency.find((i) => i.value === block.currency).sign}
                    </span>
                    <input
                      ref={inputRef}
                      type="text"
                      className={`${styles['formInput']} ${validationstate === true ? styles.formInput_error : ''} border-end-0`}
                      aria-label="Amount (to the nearest dollar)"
                      placeholder={block?.hint ? block.hint : '00.00'}
                      value={prevrepli || ""}
                      onChange={(input) => setPrevrepli(input.target.value)}
                     
                    />
                    <span className={`${styles.red}`}>{block.validationmessage}</span>
                    <span  
                    dangerouslySetInnerHTML={{__html: block.notification}} 
                    className={`${styles['widget_notification']} widget_notification`}
                    ></span>

                  </div>
                ) : block.validation === 'time' ? (


                  <div className="input-group">

                    <DatePicker
                      selected={selectedtime}
                      ref={inputRef}
                      onChange={(time) => {
                        setSelectedTime(time);
                      }}
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                      id="time"
                      name="time"
                      placeholderText={block?.hint ? block.hint : 'HH:MM'}
                      className={`${styles['formInput']} ${validationstate === true ? styles.formInput_error : ''} border-end-0`}
                      isClearable={true}
                     
                      showTimeSelect
                      showTimeSelectOnly

                      onKeyDown={async (e) => {
                        if (e.code === 'Enter' && e.key === 'Enter') {
                        
                            onReply(e);
                        
                        }
                      }}
                    />
                  <span className={`${styles.red}`}>{block.validationmessage}</span>
                    <span  
                    dangerouslySetInnerHTML={{__html: block.notification}} 
                    className={`${styles['widget_notification']} widget_notification`}
                    ></span>


                  </div>
                ) : block.validation === 'date' || block.validation === 'birthday' ? (
                  <div className="input-group">
                    <DatePicker
                      customInput={<MaskedInput mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]} />}
                      selected={selectedDate}
                      ref={inputRef}
                      onChange={(date) => {
                        if (date?.getFullYear() < 9999) {
                          setSelectedDate(date);
                        } else {
                          setSelectedDate(null);
                        }
                      }}
                      dateFormat="dd.MM.yyyy"
                      id="date"
                      name="date"
                      placeholderText={block?.hint ? block.hint : 'DD.MM.YYYY'}
                      className={`${styles['formInput']} ${validationstate === true ? styles.formInput_error : ''} border-end-0`}                                   
                      isClearable={true}
                      onKeyDown={async (e) => {
                        if (e.code === 'Enter' && e.key === 'Enter') {
                             
                                onReply(e);
                            
                        }
                      }}
                    />
                    <span className={`${styles.red}`}>{block.validationmessage}</span>
                    <span  
                    dangerouslySetInnerHTML={{__html: block.notification}} 
                    className={`${styles['widget_notification']} widget_notification`}
                    ></span>


                  </div>
                ) : block.validation === 'address' ? (
                  <div className="input-group">


                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_AUTO_COMPLETE}
                      selectProps={{
                        onChange: setAddress,
                        value: address,
                        placeholder: block?.hint ? block.hint : 'Please enter your address',
                        className: styles['google-autocomplete-input' ],
                        name: 'address',
                        id: 'address',
                        classNamePrefix: 'address-select',
                        menuPlacement: 'bottom',
                      }}
                    />

                    <span className={`${styles.red}`}>{block.validationmessage}</span>
                    <span  
                    dangerouslySetInnerHTML={{__html: block.notification}} 
                    className={`${styles['widget_notification']} widget_notification`}
                    ></span>


                  </div>
                ) : (
                  <div className={`${styles.inputgroup}`}>




                    <div className={`${styles['chat__radio__group']} chat__radio__group`}>

                        {validationstate === false ? (
                        <input
                          ref={inputRef}
                          as="input"
                          id="reply"
                          name="reply"
                          className={`${styles.formInput}`}
                          placeholder={block?.hint ? block.hint : 'Please enter your text here'}  
                         
                        />) : (
                          <input
                            ref={inputRef}
                            as="input"
                            id="reply"
                            name="reply"
                            className={`${styles.formInput_error}`}
                            placeholder={block?.usermessage ? block.usermessage : 'TRY AGAIN'}
                            
                          />
                          )}
               
                    </div>

                    <span className={`${styles.red}`}>{block.validationmessage}</span>
                    <span  
                    dangerouslySetInnerHTML={{__html: block.notification}} 
                    className={`${styles['widget_notification']} widget_notification`}
                    ></span>




                   



                  </div>

                )}

              
            </div>
          )}
          {block.type === 'input' && !waiting && (


                <div className={`${styles['buttons']}`}>
                  
                  
                         { block.next_secondary != undefined && block.next_secondary != ""  && (

                          <button   
                          onClick={async (e) => {
                              onReply(e, true) 
                          }}
                          type="submit"
                          className={`${styles['chat__next_secondary__button']}`}
                          > 
                          
                          {block.secondbutton}
                          
                          </button>

                        )}

                        {editmode === false ? (
                          <button 
                                  
                                onClick={async (e) => {       
                                  onReply(e)                                       
                                }}
                                type="submit"
                                dangerouslySetInnerHTML={{__html: block.buttontext}} 
                                className={`${styles['chat__next__button']} chat__next__button`}
                                >
                  
                          </button>
                          
                        ) : (

                        
                        <button
                              onClick={async (e) => {       
                                  console.log(reviewhome)                                   
                                  onReply(e)
                              }}
                              className={`${styles['chat__edit__button']} chat__edit__button`}
                            >Salvesta</button>                    
                  
                  )}
                
                </div>

          )}
          </div>

          {waiting && (
            <div key="indicator" className={styles['message-wrapper']}>
              <div className={styles['message-wrapper-row']}>
                <div className={`${styles['waiting-container']}`}>
                  <div className={`${styles['waiting']}`} style={{display: 'block'}}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          )}
          

        
        </div>
      </div>
      </div>
    )}
  </>
);
}


export default memo(Chat);
