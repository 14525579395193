import Select from 'react-select';
import React, {useEffect, useContext} from 'react';

import {Handle, useStore} from 'reactflow';
import {useState, useRef} from 'react';
import {useSelector} from 'react-redux';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import {ckEditorBasic} from '../../config';


import {Chatbot} from '../../states/actions';
import {validations, moneyCurrency, ckEditorInputBlock} from '../../config';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {ColorPicker} from '../ColorPicker';
import {User} from '../../states/actions';
import {InputComponent, SelectComp, TextareaComponent, PreviewComponent, NodeComponent, Progress} from './Components'
import { GlobalStateContext } from '../GlobalState';
const zoomSelector = (s) => s.transform[2];


export const InputBlock = React.memo((node) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);  

  const block = Chatbot.getBlock(node.id);
  const [secondoption, setSecondoption] = useState(false);
  const optionSelectRef = useRef(null);
  const bot = useSelector(Chatbot.get());

  const {start, review} = useSelector(Chatbot.get());
  const {_id} = useSelector(User.get());
  const [previewMode, setPreviewMode] = useState(globalState.preview ? undefined : false);
  const zoom = useStore(zoomSelector);


  useEffect(() => {
    setPreviewMode(globalState.preview);
}, [globalState.preview]);

  if (!block) {
    return null;
  }
  const {data} = block;


  const removeSecondaryButton = (e, id) => {
    e.preventDefault();
    Chatbot.removeSecondaryButton(node.id, id);
  };



  return (
    <>
     {!previewMode &&(
         <>
          <NodeComponent bot={bot} block={block} name="Input" icon="input" data={data} node={node} start={start} zoom={zoom} />
         </>
      )}

      {previewMode && (
        //  <div className={`block-body node`} onClick={review ? null : () => onEdit(node)}>
            <PreviewComponent bot={bot} data={data} node={node} start={start}/>
        //  </div>
      )}

    </>
  );
});





 {/* {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="fa fa-question"></i> Input
            </div>
            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                onClick={() => onClose(block.id)}
                className="ms-1 me-2 icon fa fa-times"
              ></i>
            </div>
          </div>
          <div className="block-body nodrag">

            
          <div className="mb-2">
              <label htmlFor="botname" className="form-label">
                Progress
              </label>
     
                   <Select
              
                    ref={optionSelectRef}
                    autoFocus={false}
                    defaultMenuIsOpen={false}
                    options={bot.progress}
                     id="choice"
                     name="choice"
                     placeholder={data.progress}
                     defaultValue={data.progressheader}
                     menuPlacement="bottom"
                     onBlur={(e) => {
                                    if(optionSelectRef.current.state.value){
                                    Chatbot.setBlockData(block.id, {isProgress: true, progress: optionSelectRef.current.state.value.label, progressheader: optionSelectRef.current.state.value.value});
                        }
                      }}
                    />
                    <span className="input-group-text btn btn-outline-success border-0" onClick={(e) => Chatbot.setBlockData(block.id, {isProgress: false, progress: ''})}>
                            <i className="fa fa-minus"></i>
                    </span>
                                

            </div>

           <div className="mb-2">
              <label htmlFor="headtext" className="form-label">
                Header for content
              </label>
              <textarea
                type="input"
                id="headtext"
                name="headtext"
                className="form-control"
                placeholder="Insert main question"
                defaultValue={data.headtext}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                }}
              />
            </div>
            <label htmlFor="content" className="form-label d-none">
              Content
            </label>
            <div id="content">
              <CKEditor
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle('height', '250px', editor.editing.view.document.getRoot());
                  });
                }}
                config={{
                  simpleUpload: {
                    uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                  },
                }}
                editor={ClassicEditor}
                data={data.content}
                onBlur={(e, editor) => {
                  Chatbot.setBlockData(block.id, {content: editor.getData()});
                }}
              />
            </div>
                
            <div>
              <label htmlFor="modal" className="form-label">
                Modal
              </label>
              <input
                type="input"
                id="modallink"
                name="modallink"
                className="form-control"
                placeholder="link name"
                defaultValue={data.modallink}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                }}
              />
              <div id="modal">
                <CKEditor
                  config={ckEditorBasic}
                  editor={ClassicEditor}
                  data={data.modal}
                  onBlur={(e, editor) => {
                    Chatbot.setBlockData(block.id, {modal: editor.getData()});
                  }}
                />
              </div>
            </div>

            <div>
              <label htmlFor="notification" className="form-label">
                Notification
              </label>
              <div id="notification">
                <CKEditor
                  config={ckEditorBasic}
                  editor={ClassicEditor}
                  data={data.notification}
                  onBlur={(e, editor) => {
                    Chatbot.setBlockData(block.id, {notification: editor.getData()});
                  }}
                />
              </div>
            </div>

            <div className="input-group">
                <Select
                  className="form-control form-control-sm"
                  placeholder="Select input type"
                  id="inputtype"
                  name="inputtype"
                  defaultValue = "input"
                  options={variables_inputtype}
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {inputtype: option.value});
                  }}
                  value={variables_inputtype.find((item) => item.value === data.inputtype)}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: 0,
                      border: 'none',
                    }),
                  }}
                />
    
            </div>

            <div className="mb-2">
              <label htmlFor="buttontext" className="form-label">
                Input name:
              </label>
              <input
                type="input"
                id="inputname"
                name="inputname"
                className="form-control"
                placeholder="Button text"
                defaultValue={data.inputname}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                }}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="buttontext" className="form-label">
                Button text:
              </label>
              <input
                type="input"
                id="buttontext"
                name="buttontext"
                className="form-control"
                placeholder="Button text"
                defaultValue={data.buttontext}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                }}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="variable" className="form-label">
                Variable (to store the answer)
              </label>
              <input
                type="input"
                id="variable"
                name="variable"
                className="form-control"
                placeholder="name"
                defaultValue={data.variable}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                }}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="validation" className="form-label">
                Validation Rule
              </label>
              <Select
                id="validation"
                name="validation"
                options={validations}
                placeholder="Type"
                value={validations.find((i) => i.value === data.validation)}
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={(option) => {
                  Chatbot.setBlockData(block.id, {validation: option.value});
                }}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="hint" className="form-label">
                Hint Message
              </label>
              <input
                type="input"
                id="hint"
                name="hint"
                className="form-control"
                placeholder="Type input hint message here"
                defaultValue={data.hint}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                }}
              />
            </div>
            {data.validation === 'money' && (
              <div>
                <label htmlFor="Choose Currency" className="form-label">
                  Choose Currency
                </label>
                <Select
                  id="currency"
                  name="currency"
                  options={moneyCurrency}
                  placeholder="Type"
                  value={moneyCurrency.find((i) => i.value === data.currency)}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {currency: option.value});
                  }}
                />
              </div>
            )}
            <div>
              <label htmlFor="validationMessage" className="form-label">
                Validation Message
              </label>
              <input
                type="input"
                id="validationMessage"
                name="validationMessage"
                className="form-control"
                placeholder="Validation Message"
                defaultValue={data.validationMessage}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {validationMessage: e.target.value});
                }}
              />
            </div>


            


       
            
                 
            <div className="input-group mb-3">


                    {block.next_secondary != "" ? (
                        <div>
                          <label htmlFor="secondbutton" className="form-label">
                          Second button:
                          </label>
                          <input
                          type="input"
                          id="secondbutton"
                          name="secondbutton"
                          className="form-control"
                          placeholder="Button text"
                          defaultValue={data.secondbutton}
                          onBlur={(e) => {
                            Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                          }}
                          />

                          <Handle id="next_secondary" className="block-handle block-handle-right" type="source_second" position="right" />

                              <span
                              className="input-group-text btn btn-outline-danger border-0"
                              onClick={ async (e) => { 
                                Chatbot.setBlockData(block.id, {next_secondary : ""})
                                setSecondoption(false)
                              }}
                            
                            >
                              <i className="far fa-trash-alt"></i>
                            </span>
                      </div>
                         
                          
                    ) : (
                      <span
                      className="input-group-text btn btn-outline-danger border-0"
                      onClick={(e) =>  setSecondoption(true)
                      }        
                      >
                      <i className="fa fa-plus"></i>
                    </span>
                    )}
                    
                   
                    

                          
                 </div>

           


          </div>
        </div>
      )} */}

       {/*
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className={`${data.isProgress === true ? 'isprogress' : ''} node input-block`}
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          <div className="my-3" dangerouslySetInnerHTML={{__html: data.headtext}} />
          

          <span className="badge rounded-pill bg-primary badge-bl">{data.variable}</span>
          <hr />
          {data.isProgress &&(
          <div className="my-3 h1" dangerouslySetInnerHTML={{__html: data.progress}} />
          )}

        <div className="input-group mb-3">
                 
                
        {data.next_secondary != "" || secondoption ? (
                        <div>
                          
                          <Handle id="next_secondary" className="block-handle block-handle-right" type="source_second" position="right" />

                              <span
                              className="input-group-text btn btn-outline-danger border-0"
                              onClick={ async (e) => { 
                                Chatbot.setBlockData(block.id, {next_secondary : ""})
                                setSecondoption(false)
                              }}
                            
                            >
                              <i className="far fa-trash-alt"></i>
                            </span>
                      </div>
                         
                          
                    ) : (
                      <span
                      className="input-group-text btn btn-outline-danger border-0"
                      onClick={(e) =>  setSecondoption(true)
                      }        
                      >
                      <i className="fa fa-plus"></i>
                    </span>
                    )}

        </div>

          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
        </div>
      )} */}
