import React, {useEffect, useState, useRef} from 'react';
import {Field} from 'formik';

import {useHistory, useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {Form, DataTable} from '../components';
import Select from 'react-select';

import {languages, countries} from '../config';

import {Chatbot, User} from '../states/actions';
import {axios} from '../libs';

import {find as _find, filter as _filter} from 'lodash';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';

import {SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import {InputComponent} from '../components/ChatbotBuilder/Components'


const DragHandle = sortableHandle(() => <span> : : </span>);

const SortableItem = SortableElement(({ value, handleInputChange, remove }) => (
  <div>
    <DragHandle />
    <input
      type="text"
      defaultValue={value}
      onBlur={handleInputChange}
    />
    <button onClick={remove}>Remove</button>

  </div>
));


const SortableList = SortableContainer(({ items, handleInputChange, remove }) => (
  <ul>
    {items.map((item, index) => (
      <SortableItem
        key={item.id}
        index={index}
        value={item.value}
        handleInputChange={(e) => handleInputChange(e, index)}
        remove={(e) => remove(item.id)}
      />
    ))}
  </ul>
));


export const Chatbots = () => {
  const [refetch, setrefetch] = useState(false);
  const {push} = useHistory();
  const {chatbotId} = useParams();
  const {blocks, ...data} = useSelector(Chatbot.get());
  const {_id} = useSelector(User.get());


  const [isModalOpen, setIsModalOpen] = useState(false);

  const [copydata, setCopyData] = useState();
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  
  
  useEffect(() => {

    if (chatbotId) {
      (async () => {
        const {blocks, ...data} = await Chatbot.fetch(chatbotId);
        Chatbot.set(data);
      })();
    } else {
      Chatbot.clear();
    }
  }, [chatbotId]);


  const onSuccess = ({chatbot}) => {
    setrefetch(false);
    Chatbot.set(chatbot);
    if (chatbotId) {
      push('/chatbots');
      setrefetch(true);
    } else {
      push(`/chatbot-builder/${chatbot._id}`);
    }
  };

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (value) => {
        return (
          <span className="table-text">
            {value}
          </span>
        );
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (value) => {
        return (
          <span className="badge bg-primary" role="button">
            {value}
          </span>
        );
      },
    },
    {
      dataField: 'user',
      text: 'Created By',
      formatter: ({firstName, lastName}) => {
        return `${firstName} ${lastName}`;
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Last modified',
      formatter: (cell) => {
        return cell.split('T')[0];
      },
    },
  ];

  const submissionsColumns = [
    {
      dataField: '_id',
      text: 'S.n',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      style: {
        width: 10,
      },
    },
    {
      dataField: 'chatbot.name',
      text: 'Name',
    },  
    {
      dataField: 'chatbot.img',
      text: 'img',
    },
    {
      dataField: 'chatbot.description',
      text: 'Description',
      style: {
        width: '50%',
      },
    },
    {
      dataField: 'user',
      text: 'Created By',
      formatter: ({firstName, lastName}) => {
        return `${firstName} ${lastName}`;
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Last modified',
      formatter: (cell) => {
        return cell.split('T')[0];
      },
    },
  ];




const getBlockId = async () => {
  let {id} = await axios.get('/block/id');
  //error checking
  return id;
};

/* To copy blocks all the ids need to be regenerated and also the logical connections between blocks */
const updateIDsAndReferences = async (data, newbotid) =>  {
  let idMap = {};

  // Create mapping of old IDs to new IDs
  for (let key in data) {

      idMap[key] = await getBlockId();
  }

  // Replace object keys and IDs with new IDs
  let updatedData = {};
  for (let key in data) {
      let newObjKey = idMap[key];
      updatedData[newObjKey] = { ...data[key], id: newObjKey};
      // If the data object has an _id field, update that as well
      if (updatedData[newObjKey].data && updatedData[newObjKey].data._id) {
          updatedData[newObjKey].data._id = newObjKey;
      }
      if (updatedData[newObjKey].data && updatedData[newObjKey].data.chatbotId) {
        updatedData[newObjKey].data.chatbotId = newbotid;
    }
  }

  // Update references
  for (let key in updatedData) {
      let obj = updatedData[key];
      if (obj.data) {
          if (obj.data.next) obj.data.next = idMap[obj.data.next] || obj.data.next;
          if (obj.data.nextOnTrue) obj.data.nextOnTrue = idMap[obj.data.nextOnTrue] || obj.data.nextOnTrue;
          if (obj.data.nextOnFalse) obj.data.nextOnFalse = idMap[obj.data.nextOnFalse] || obj.data.nextOnFalse;
          if (obj.data.next_secondary) obj.data.next_secondary = idMap[obj.data.next_secondary] || obj.data.next_secondary;
          if (obj.data.options) {
              for (let optionKey in obj.data.options) {
                  if (obj.data.options[optionKey].next) {
                      obj.data.options[optionKey].next = idMap[obj.data.options[optionKey].next] || obj.data.options[optionKey].next;
                  }
              }
          }
          if (obj.data.reviews) {
              for (let reviewKey in obj.data.reviews) {
                  if (obj.data.reviews[reviewKey].element && obj.data.reviews[reviewKey].element.id) {
                      obj.data.reviews[reviewKey].element.id = idMap[obj.data.reviews[reviewKey].element.id] || obj.data.reviews[reviewKey].element.id;
                      obj.data.reviews[reviewKey].element.value = idMap[obj.data.reviews[reviewKey].element.value] || obj.data.reviews[reviewKey].element.value;
                      obj.data.reviews[reviewKey].element.content = idMap[obj.data.reviews[reviewKey].element.content] || obj.data.reviews[reviewKey].element.content;
                  }
              }
          }
      }
      if (obj.source) obj.source = idMap[obj.source] || obj.source;
      if (obj.target) obj.target = idMap[obj.target] || obj.target;
  }

  return updatedData;
}

/* Create copies into database */
const createBlocksCopies = async (data, newbotid) =>{
  
      /* using let handle as obj, because handle will be the object name in meta data of handle obj, otherwise handle as object name is not needed */

      for (let key in data) {
          let handle = data[key];
          Chatbot.setBlock(handle);

          if(handle.data.type === "handle"){
           const _id = handle.id
           const response = Chatbot.setBlockData(
            _id,
            {
              _id: handle.id,
              type: 'handle',
              chatbotId: newbotid,
              meta: {handle},
            },
            true,
          );
          console.log(response)
         } else {
          const response = await Chatbot.setBlockData(handle.id, {}, true);
          console.log(response)
        }
        
         
    }
  


}

/* not needed atm */
function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}



const onDelete = ({_id}) => {
    setrefetch(false);
    window.nConfirm({
      title: 'Warning',
      text: 'Are you sure you want to delete this chatbot?',
      icon: 'fa-exclamation-circle',
      onYes: () => {
        deleteChatbot(_id);
      },
    });
};

const onCopy = ({_id}) => {
    setrefetch(false);
    window.nConfirm({
      title: 'Warning',
      text: 'Are you sure you want to copy this chatbot?',
      icon: 'fa-exclamation-circle',
      onYes: () => {
        copyChatbot(_id);
      },
    });
  };

const deleteChatbot = async (id) => {
    await axios.delete(`/chatbot/${id}`);
    setrefetch(true);
  };

const copyChatbot = async (id) => {

    /* Need to copy chatbot first and get its id */
    const newbot = await axios.post(`/chatbot/cloneBot`, {chatbotId: id});
    const newbotid = newbot.message;
    setrefetch(true);

    /* Copy blocks into new arry with new ids + add new chatbotId */
    const {blocks} = await Chatbot.fetch(id);
    console.log(blocks)

    //const newobject = deepClone(blocks);
    const newdata = await updateIDsAndReferences(blocks, newbotid)
    console.log(newdata)
    
    /* Add these new blocks into system */
    createBlocksCopies(newdata, newbotid)

  };

  const onEdit = (data) => {

    console.log(chatbotId)
    if(chatbotId === undefined)
    {push(`chatbots/${data._id}`) }else
    {push(`${data._id}`)} 
  };

  const onData= (data) => {

    console.log(chatbotId)
    if(chatbotId === undefined)
    {push(`chatbot/conversation/${data._id}`) }else
    {push(`${data._id}`)} 
  };

  const onPreview= (data) => {
    window.open(`https://app.hugo.legal/hugoapp?bot=${data._id}`, '_blank');
  };



  return (
    <>

      <div className="row table_v2">
        <div className="col p-0">
          <div className="x_panel chatbot-builder">

            <div className="header-menu d-flex justify-content-between">

             {/*  <h2>
                Documents
              </h2> */}
             

              <div className="d-flex justify-content-end page-top-container">
                  
                      <div className='btn-add link' onClick={openModal}>
                         + Add APP
                      </div>
                 
              </div>


             
            </div>


            <div className="x_content">

                <div className="page-container">



                      <DataTable
                      refetch={refetch}
                      navigateTo="/chatbot-builder"
                      url="/chatbot"
                      dataKey="chatbots"
                      tableHeading="Chatbots"
                      columns={tableColumns}
                      onDelete={onDelete}
                      onCopy={onCopy}
                      onEdit={onEdit}
                      onData={onData}
                      onPreview={onPreview}
                      isPreview={User.isAdmin()}
      
                    
                     />
               </div>
            </div>
          </div>
        </div>
      </div>


      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="modalclose" onClick={closeModal}>
              &times;
            </span>

            <div className='modal_header mb-4 my-4'>
              <h2>Create new app</h2>
            </div>
          
            <Form usePUT={chatbotId ? true : false} url="/chatbot" initialValues={data} onSuccess={onSuccess}>
             
             
                <div className="row mb-5">
                  <label htmlFor="name" className="form-label comp-header">
                     App name
                  </label>

                  <div className="">
                    <Field
                      as="input"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Your app name"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <button className="light-btn" onClick={closeModal}>
                    Cancel
                  </button>
                  <button type="submit" className="main-btn">
                    Save
                  </button>
                </div>


              </Form>

          </div>
        </div>
      )}


  

     {/*  {User.isAdmin() && (
        <div className="row">
          <div className="col">
            <div className="x_panel">
              <div className="x_title">
                <h2>Chatbot Subimissions</h2>
                <div className="clearfix"></div>
              </div>
              <div className="x_content">
                <DataTable
                  navigateTo="/chatbot-review"
                  url="/chatbot/submissions"
                  dataKey="chatbots"
                  tableHeading="Chatbots"
                  columns={submissionsColumns}
                  onDelete={() => {}}
                  onEdit={onEdit}
                />
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};
