import React, {useState, useEffect} from 'react';
import {Handle} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import {find as _find} from 'lodash';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';

import {Chatbot} from '../../states/actions';
import {ckEditorEmailBlock} from '../../config';
import {IntNodeComponent, InputComponent, SelectComp} from './Components'


export const EmailBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start} = useSelector(Chatbot.get());

  if (!block) {
    return null;
  }

  const {data} = block;



  return (
    <>
      <IntNodeComponent name="Email" icon="email" data={data} node={node} start={start}/>
    </>
  );
});


export const EmailEditor = ({nodeid, block, data}) => {

  const {blocks} = useSelector(Chatbot.get());
  const documentBlockExist = _find(blocks, {type: 'documentBlock'});
  const [documents, setdocuments] = useState([]);
  const variableOptions = Chatbot.getBlockVariables();


  if (!block) {
    return null;
  }

  const loadDocuments = async (inputValue) => {
    const {documents} = await Chatbot.fetchDocuments();
    setdocuments(documents);
    return documents?.map((i) => ({label: i.name, value: i.id}));
  };
  const handleBlur = (e) => {   
    console.log(e.target.name, e.target.value)
    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
  };


return (
  <>

          <SelectComp data={data} name="Email" dataname="email" placeholder="Select" options={variableOptions} block={block} value="label" />

          <SelectComp data={data} name="CC" dataname="cc" placeholder="Select" options={variableOptions} block={block} value="label" />

          <SelectComp data={data} name="BCC" dataname="bcc" placeholder="Select" options={variableOptions} block={block} value="label" />


          <InputComponent data={data} name="Subject" dataname="subject" onBlur={handleBlur}/>   

          <div className="mb-3">
              <label htmlFor="content" className="form-label d-none">
                Content
              </label>
              <div id="content">
                <CKEditor
                  config={ckEditorEmailBlock}
                  editor={ClassicEditor}
                  data={data.content}
                  onBlur={(e, editor) => {
                    Chatbot.setBlockData(block.id, {content: editor.getData()});
                  }}
                />
              </div>
          </div>   

          {documentBlockExist && (
              <div className="mb-3">
                <label htmlFor="attachment" className="form-label ">
                  Attachment
                </label>
                <AsyncSelect
                  isMulti
                  className="form-control form-control-sm editor-select"
                  classNamePrefix="editor-select"
                  id="attachment"
                  placeholder="Select Attachment"
                  name="attachment"
                  loadOptions={loadDocuments}
                  value={documents
                    ?.filter((doc) => data?.attachment?.includes(doc.id))
                    .map((i) => ({label: i.name, value: i.id}))}
                  defaultOptions
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {
                      assign: {...data, attachment: option.map((i) => i.value)},
                    });
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: 0,
                      border: 'none',
                    }),
                  }}
                />
              </div>
           
           
        )}

        <InputComponent data={data} name="Waiting message" dataname="waitmessage" onBlur={handleBlur}/>



                {/* <div className="mb-3 row">
              <label htmlFor="cc" className="col-sm-2 col-form-label">
                CC
              </label>
              <div className="col-sm-10">
                <CreatableSelect
                  isClearable
                  className="form-control form-control-sm"
                  id="cc"
                  isMulti
                  options={data?.cc?.map((i) => ({label: i, value: i}))}
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {cc: option.map((i) => i.value)});
                  }}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="bcc" className="col-sm-2 col-form-label">
                BCC
              </label>
              <div className="col-sm-10">
                <CreatableSelect
                  className="form-control form-control-sm"
                  id="bcc"
                  isMulti
                  options={data?.bcc?.map((i) => ({label: i, value: i}))}
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {bcc: option.map((i) => i.value)});
                  }}
                />
              </div>
            </div> */}
                
  </>
  
)
};