import React, {useEffect, useRef, useState} from 'react';
import Select from 'react-select';

import {useSelector} from 'react-redux';
import {Chatbot} from '../../states/actions';
import {IntNodeComponent, InputComponent, SelectComp, onEdited} from './Components'



export const DataBlock = (node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());


  if (!block) {
    return null;
  }
  const {
    data,
  } = block;


  return (
    <>
    
      <IntNodeComponent name="Data" icon="data" data={data} node={node} start={start}/>

     
    </>
  );
};


export const DataEditor = ({nodeid, block}) => {
 
  const refTxtProperty = useRef(null);
  const refTxtValue = useRef(null);

  const {
    data: {data = {}, datatype},
  } = block;
  const [datafields, setDatafields] = useState(data); 

  useEffect(() => {

    if (data) {
      (async () => {
        setDatafields(data)
      })();
    } 
  }, [data]);

  if (!block) {
    return null;
  }
  let variables_datatype = [
    {
      label: 'Import data',
      value: 'import',
      content: 'import',
    },
    {
      label: 'data',
      value: 'data',
      content: 'data',
    },
  ];


/*   const handleKeyChange = async (e, value, index) => {
    console.log(data, datafields, value, index)
    const newItems = datafields;
    newItems[index] = {
      [e.target.value]: value,
    }
    setDatafields(newItems);
  //  Chatbot.setBlockData(nodeid, {data: newItems});
  };
   */
 /*  const onBlur = (e, value, key) => {
    console.log(datafields)
    //removeDataField(e, key) 
   // const newItems = datafields;
    const newItems = {...datafields, [e.target.value]: value};
    delete newItems[key];
    console.log(newItems)
    setDatafields(newItems);
    Chatbot.setBlockData(nodeid, {data: newItems}) //update key 



 //   Chatbot.setBlockData(nodeid, {data: {[e.target.value]: value}}) //update key
    // update value : Chatbot.setBlockData(nodeid, {data: {[key]: e.target.value}})
  }
  


  
  const addDataField = (e) => {
    e.preventDefault();
    const property = refTxtProperty.current.value;
    const value = refTxtValue.current.value;
    if (property !== '') {
      Chatbot.setBlockData(nodeid, {data: {[property]: value}});
      refTxtProperty.current.value = '';
      refTxtValue.current.value = '';
    }
    console.log(data)
  };
  const removeDataField = (e, key) => {
    e.preventDefault();
    Chatbot.removeDataBlock(nodeid, key);
    onEdited('removed');
  }; */


  const addDataField = (e) => {
    e.preventDefault();
    const id = Date.now().toString();
    const text = refTxtProperty.current.value;
    const value = refTxtValue.current.value;
    if (text !== '') {
      Chatbot.setBlockData(nodeid, {data: {[id]: {id, text, value}}});
      refTxtProperty.current.value = '';
      refTxtValue.current.value = '';
      onEdited('added');
    }
  };
  const removeDataField = (e, key) => {
    e.preventDefault();
    Chatbot.removeDataBlock(nodeid, key);
    onEdited('removed');
  };

return (
  <>
         
     

        {/*  <SelectComp data={data} name="Data" dataname="datatype" placeholder="Set data method" options={variables_datatype} block={block}/> */}

        <div className='tab-container'>
          <div className={`tab btn-section ${datatype === "import" ? 'active' : ''}`} onClick={() => Chatbot.setBlockData(block.id, {datatype: "import"})}>
                    <span></span>
                      Import
                    </div>
                    <div className={`tab btn-section ${datatype === "data" ? 'active' : ''}`} onClick={() => Chatbot.setBlockData(block.id, {datatype: "data"})}>
                      <span></span>
                      Data
          </div>
          </div>

         

          <br/><br/>

         




            {/*      <div className="double-input mb-3">
                    
                    <div className='inputs'>

                        <input
                          type="text"
                          id="txtProperty"
                          className="form-control top"
                          placeholder="property"
                          ref={refTxtProperty}
                          onKeyPress={(e) => (e.key === 'Enter' ? addDataField(e) : e)}
                        />
                  
                        <input
                          type="text"
                          id="txtValue"
                          className="form-control bottom"
                          placeholder="value"
                          ref={refTxtValue}
                          onKeyPress={(e) => (e.key === 'Enter' ? addDataField(e) : e)}
                          onBlur={(e) => addDataField(e)}
                        />
                    </div>
                    <span
                      className="icon"
                      onClick={(e) => addDataField(e)}
                    >
                      <i className="fa fa-plus"></i>
                    </span>
                    
                </div> */}
 
                <div className='double-input mb-3'>
                      {/* <span className="input-group-text btn  ">
                            <i className="fa"></i>
                      </span> */}
                    
                      <div className='inputs'>
                  
                      <input
                          type="input"
                          id="txtText"
                          className="form-control top"
                          placeholder="Name"
                          ref={refTxtProperty}
                          onKeyPress={(e) => (e.key === 'Enter' ? addDataField(e) : e)}
                        />
                        <input
                          type="input"
                          id="txtValue"
                          className="form-control bottom"
                          placeholder="value"
                          ref={refTxtValue}
                          onKeyPress={(e) => (e.key === 'Enter' ? addDataField(e) : e)}
                          onBlur={(e) => addDataField(e)}
                        />
                      </div>

                      <span className="icon" onClick={(e) => addDataField(e)}>
                      <i className="fa fa-plus"></i>
                    </span>
                  </div>
           
               {/*  {Object.entries(data).map(([key, value], i) => {
                  return (
                    <div key={i} className="double-input mb-3">
                      
                      <div className='inputs'>
                        <input
                          type="text"
                          name="property"
                          className="form-control top"
                          placeholder="property"
                          defaultValue={key}
                          onBlur={(e) => onBlur(e, value, key)}
                        />
                      
                     
                        <input
                          type="text"
                          name="value"
                          className="form-control bottom"
                          placeholder="value"
                          defaultValue={value}
                          onBlur={(e) =>  onBlur(e, key, value)}
                        />
                      
                      </div>
                        <span
                          className="icon red"
                          onClick={(e) => removeDataField(e, key)}
                        >
                          <i className="far fa-trash-alt"></i>
                        </span>
                      
                   </div>
                  );
                })} */}



                {Object.keys(data)
                      .sort()
                      .map((key, i) => {
                        const item = data[key];
                        return (
                          <div key={item.id} className="double-input mb-3">
                          

                                  {/* drag and drop order button
                                  
                                  <span className="input-group-text btn  ">
                                      <i className="fa fa-bars"></i>
                                </span> */}
                                
                                  <div className='inputs'>
                              
                                  <input
                                      name={`decisions.${item.id}.text`}
                                      className="form-control"
                                      placeholder="option"
                                      defaultValue={item.text}
                                      onChange={onEdited}
                                      onBlur={(e) => {
                                        Chatbot.setBlockData(nodeid, {data: {[item.id]: {id: item.id, text: e.target.value}}});
                                      }}
                                    />
                                    <input
                                      id={`${item.id}.value`}
                                      name={`decisions.${item.id}.value`}
                                      className="form-control"
                                      placeholder="value"
                                      defaultValue={item.value}
                                      onChange={onEdited}
                                      onBlur={(e) =>
                                        Chatbot.setBlockData(nodeid, {data: {[item.id]: {id: item.id, value: e.target.value}}})
                                      }
                                    />
                                  </div>
                                  <span className="icon red" onClick={(e) => removeDataField(e, item.id)}>
                                  <i className="fa fa-trash-alt"></i>
                                </span>
                          </div>
                        );
                    })}


         
  </>
  

)
};