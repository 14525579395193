import React, {useEffect, useRef, useState, useContext} from 'react';
import {Handle, useStore} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onEdit} from '../../helpers/block-helper';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import {InputComponent, TextareaComponent, PreviewComponent, NodeComponent, Progress} from './Components'

import {User} from '../../states/actions';
import { GlobalStateContext } from '../GlobalState';

const zoomSelector = (s) => s.transform[2];


export const ShareBlock = React.memo((node) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);  

  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const bot = useSelector(Chatbot.get());
  const {_id} = useSelector(User.get());
  const [previewMode, setPreviewMode] = useState(globalState.preview ? undefined : false);
  const zoom = useStore(zoomSelector);



  useEffect(() => {
    setPreviewMode(globalState.preview);
}, [globalState.preview]);

  if (!block) {
    return null;
  }

  const {data} = block;
  
  return (
    <>

      {!previewMode &&( 
         <>
          <NodeComponent bot={bot} block={block} name="Share" icon="share" data={data} node={node} start={start} zoom={zoom}/>
          </>
      )}

      {previewMode && (
       // <div className={`block-body node`} onClick={review ? null : () => onEdit(node)}>
          <PreviewComponent bot={bot} data={data} node={node} start={start}/>
       // </div>
      )}

    </>
  );
});

export const ShareEditor = ({nodeid, block, data, options}) => {

  const {_id} = useSelector(User.get());

  if (!block) {
    return null;
  }


  const handleBlur = (e) => {   
    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
  };

  return(
    <>
              
              <div className='section'>Share</div>
              <InputComponent data={data} name="Add friends hint" dataname="emailhint" onBlur={handleBlur}/>  
              <InputComponent data={data} name="Add friends validation" dataname="validationEmail" onBlur={handleBlur}/>  

              <InputComponent data={data} name="Email subject" dataname="subject" onBlur={handleBlur}/>  
              <InputComponent data={data} name="Message hint" dataname="hint" onBlur={handleBlur}/>  
              <TextareaComponent data={data} name="Message" dataname="defaultmessage" onBlur={handleBlur} rows="4"/>
              <InputComponent data={data} name="Message validation" dataname="validationMessage" onBlur={handleBlur}/>  


              <div className="mb-3">
              <label htmlFor="lockedmessage" className="form-label comp-header">
                 Locked Message
                  </label>
        

                <div id="lockedmessage">
                  <CKEditor
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle('height', 'auto', editor.editing.view.document.getRoot());
                      });
                    }}
                    config={{
                      simpleUpload: {
                        uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                      },
                    }}
                    editor={ClassicEditor}
                    data={data.lockedmessage}
                    onBlur={(e, editor) => {
                      Chatbot.setBlockData(block.id, {lockedmessage: editor.getData()});
                    }}
                  />
                </div>   
       
            </div>        


    </>
  )

}