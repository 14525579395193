import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {Handle} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {Form} from '../';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onSuccess, onEdit, onSetStart, onRemove, onClose, isLightColor} from '../../helpers/block-helper';
import {Link} from 'react-router-dom';
import {IntNodeComponent, InputComponent, SelectComp} from './Components'



export const DocumentBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const [documents, setdocuments] = useState([]);
  const {start, review} = useSelector(Chatbot.get());
  useEffect(() => {
    const fetchDocuments = async () => {
      const {documents} = await Chatbot.fetchDocuments();
      setdocuments(documents?.map((i) => ({label: i.name, value: i.id})));
    };
    fetchDocuments();
  }, []);

  if (!block) {
    return null;
  }
  const {data} = block;

  return (
    <>
        <IntNodeComponent name="Document" icon="document" data={data} node={node} start={start}/>
    </>
  );
});
export const DocumentEditor = ({nodeid, block}) => {
  const [documents, setdocuments] = useState([]);
  useEffect(() => {
    const fetchDocuments = async () => {
      const {documents} = await Chatbot.fetchDocuments();
      setdocuments(documents?.map((i) => ({label: i.name, value: i.id})));
    };
    fetchDocuments();
  }, []);

  const {data} = block;

  if (!block) {
    return null;
  }

  const handleBlur = (e) => {   
    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
  };


return (
  <>
         
         <Form url="/block/document" initialValues={data} onSuccess={onSuccess} successNotification={false}>
            

              <div className="mb-2">
 
                <label htmlFor="documentId" className="form-label comp-header">
                  Document
                </label>
                <Select
                  id="documentId"
                  name="documentId"
                  options={documents}
                  placeholder="Select Document"
                  value={documents?.find((i) => i.value === data.documentId)}
                  className="form-control form-control-sm editor-select"
                  classNamePrefix="editor-select"
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {documentId: option.value, documentName: option.label});
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: 0,
                      border: 'none',
                    }),
                  }}
                />

              </div>



              
              <InputComponent data={data} name="File name (attachment name, file name for drive)" dataname="fileName" onBlur={handleBlur}/>

              <InputComponent data={data} name="Variable (to store the document url drive path)" dataname="variableForUrl" onBlur={handleBlur}/>

              <InputComponent data={data} name="Variable (to store the document download url)" dataname="variableForDownload" onBlur={handleBlur}/>

              <InputComponent data={data} name="Waiting message" dataname="waitmessage" onBlur={handleBlur}/>


              <a target="_blank" rel="noreferrer" href={`https://docs.google.com/document/d/${data.documentId}/edit`}>
                 Open document
               </a>

                <br/><br/>
              <div className="form-footer">
                <Link to="/documents" target="_blank">
                  <button type="button" className="btn btn-dark btn-sm">
                    Create new
                  </button>
                </Link>
              </div>
         </Form>

         
  </>
  

)
};