/* import React, {useEffect, useState} from 'react';
import {Handle} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onEdit, onSetStart, onClose, onRemove} from '../../helpers/block-helper';
import Select from 'react-select';
import {axios} from '../../libs';

export const TeleportBlock = (node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const [bots, setBots] = useState([]);
 
  useEffect(() => {
    async function fetchBots() {
      try {
        const {chatbots} = await axios.get('/chatbot');
        setBots(chatbots.map((i) => ({label: i.name, value: i._id})));
      } catch (error) {
        return error;
      }
    }
    fetchBots();
  }, []);

  const botOptions = Chatbot.getBlockVariables();

  if (!block) {
    return null;
  }
  const {data, editMode} = block;
  return (
    <>
      {editMode && (
        <div className="block active" onDoubleClick={() => onEdit(node)}>
 
          <div className="block-body nodrag">
          <div className="mb-2">
                  <label htmlFor="bot" className="form-label">
                    To Bot
                  </label>
                  <Select
                    id="bot"
                    name="bot"
                    placeholder="Bot"
                    className="form-control form-control-sm react-select-container"
                    classNamePrefix="react-select"
                    options={bots}
                    value={bots.find((item) => item.value === data.bot)}
                    onChange={(option) => {
                      Chatbot.setBlockData(block.id, {bot: option.value});
                    }}
                  />
               
              </div>
          </div>
        </div>
      )}
      {!editMode && (
        <div className="node text-block" onDoubleClick={review ? null : () => onEdit(node)}>
          <a href={data.url}>{data.content}</a>
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
          <h4>TELEPORT</h4>
          <i>{data.bot}</i>



        </div>
      )}

    </>
  );
}; */



import React, {useEffect, useRef, useState} from 'react';
import Select from 'react-select';
import {axios} from '../../libs';

import {useSelector} from 'react-redux';
import {Chatbot} from '../../states/actions';
import {IntNodeComponent, InputComponent, SelectComp, onEdited} from './Components'



export const TeleportBlock = (node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());




  
  
  if (!block) {
    return null;
  }
  const {
    data,
  } = block;


  return (
    <>
    
      <IntNodeComponent name="Teleport" icon="teleport" data={data} node={node} start={start}/>
     
    </>
  );
};


export const TeleportEditor = ({nodeid, block, reviews}) => {

  const [bots, setBots] = useState([]);
 
  const variableOptions = Chatbot.getBlocksToReview();
  const refTxtReviewelement = useRef(null);

  useEffect(() => {
    async function fetchBots() {
      try {
        const {chatbots} = await axios.get('/chatbot');
        setBots(chatbots.map((i) => ({label: i.name, value: i._id})));
      } catch (error) {
        return error;
      }
    }
    fetchBots();
  }, []);


  const addReviewField = (e) => {
    e.preventDefault();
    const id = Date.now().toString();
    const element = refTxtReviewelement.current.state.value;
    if (element !== '') {
      Chatbot.setBlockData(nodeid, {reviews: {[id]: {id, element}}});
      refTxtReviewelement.current.value = '';
    }
  };
  const removeReviewField = (e, id) => {
    e.preventDefault();
    Chatbot.removeBlockReviews(nodeid, id);
  };


  let lang = [
    {
      label: 'Et',
      value: 'et',
      content: 'et',
    },
    {
      label: 'En',
      value: 'en',
      content: 'en',
    },
    {
      label: 'Ru',
      value: 'ru',
      content: 'ru',
    },
  ];

  if (!block) {
    return null;
  }
  const {
    data,
  } = block;

return (
  <>
         
     
            <SelectComp data={data} name="BOT" dataname="bot" placeholder="Select" options={bots} block={block} value="value" />

            <SelectComp data={data} name="Language" dataname="lang" placeholder="Select" options={lang} block={block} value="value"/>


         

            <div className='section'>DATAS TO SEND</div>

       

            <div id="reviews">


              <label htmlFor="reviews" className="d-none">
                Datas
              </label>



              <div className="mb-4 single-input">

                  <Select
                      className="form-control form-control-sm editor-select"
                      classNamePrefix="editor-select"
                      placeholder="Select"
                      id="reviewelement"
                      name="reviewelement"
                      options={variableOptions}
                      onKeyPress={(e) => (e.key === 'Enter' ? addReviewField(e) : e)}
                      ref={refTxtReviewelement}
                      value={variableOptions.find((item) => item.label === data.reviewelement)}
                      onKeyDown={async (e) => {
                        if (e.code === 'Enter' && e.key === 'Enter') {
                          const selectedOption = refTxtReviewelement.current.state.label;
                          if (selectedOption) {
                            console.log(selectedOption.label);
                          }
                        }
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: 0,
                          border: 'none',
                        }),
                      }}
                  />

              <button onClick={(e) => addReviewField(e)} className="fa fa-plus icon"></button>


              </div>


              

            </div>

            {Object.keys(reviews)
                  .sort()
                  .map((key, i) => {
                    const item = reviews[key];
                    return (
                      <>
                        <div key={item.id} className="single-input my-3">

                          <div className="form-control valuefield">{item.element.label}</div>
                          <button  onClick={(e) => removeReviewField(e, item.id)} className="fa fa-trash-alt icon red"></button>

                        </div>
                        </>
                    );
              })}

         
         


         
  </>
  

)
};