import {Field} from 'formik';
import React, {memo, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import Select from 'react-select';

import stringFormat from 'string-format';
import {Form} from '../Form';
import {axios} from '../../libs';
import {Chatbot} from '../../states/actions';
import {countries, languages} from '../../config';
import './chatpreview.css';

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  minWidth: 0,
};

export const ChatPreview = memo(() => {
  const waitForinput = ['input', 'decision'];
  const optionSelectRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [block, setBlock] = useState({});
  const {getRootProps, getInputProps} = useDropzone({
    onDrop: (acceptedFiles) => {
      const updatedFiles = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      setFiles([...files, ...updatedFiles]);
      _scroll();
    },
  });
  const [messages, setMessage] = useState([]);
  const [waiting, setWaiting] = useState(true);
  const [state, setState] = useState({
    started: false,
    preview: false,
  });
  const [meta, setmeta] = useState({
    language: 'en',
    country: 'est',
  });

  let {started, preview} = state;
  const onChatPreview = async () => {
    setState({started, preview: !preview});
    if (!state.started) {
      //start bot preview
      setState({started: true, preview: !preview});
      const {start} = Chatbot.get('current');
      getNextBlock(start);
    }
  };

  const onStartAgain = () => {
    setMessage([]);
    const {start} = Chatbot.get('current');
    getNextBlock(start);
    setFiles([]);
  };

  const getNextBlock = async (_id) => {
    setWaiting(true);
    let {block, message} = await axios.post(`/chat-preview`, {_id});
    setWaiting(false);
    if (block) {
      setBlock(block);
      if (block.content && block.type !== 'email' && block.type !== 'multipleChoice') {
        setMessage((state) => [
          ...state,
          {
            _id: block._id,
            content: block.content,
            user: 'system',
            type: block.type,
            decisions: block?.decisions || {},
            bookingTypes: block?.bookingTypes || {},
          },
        ]);
      }
      if (!waitForinput.includes(block.type)) {
        let chatbot = Chatbot.get('current');
        switch (block.type) {
          case 'condition': {
            const result = eval(`"${chatbot.data[block.variable]}" ${block.operator} "${block.value.toString()}"`);
            if (result) {
              getNextBlock(block.nextOnTrue);
            } else {
              getNextBlock(block.nextOnFalse);
            }
            break;
          }
          case 'document': {
            const {data: variables, name} = Chatbot.get('current');
            const {documentId, fileName, variableForUrl, variableForDownload} = block;
            setWaiting(true);
            _scroll();
            const {download, view, generatedDocumentId} = await axios.post('/document/generate', {
              variables,
              documentId,
              fileName,
              chatBot: name,
            });
            Chatbot.setData({
              [variableForUrl]: view,
              [variableForDownload]: download,
              [documentId]: generatedDocumentId,
              [`${documentId}-fileName`]: stringFormat(fileName, variables),
            });
            // setMessage((state) => [
            //   ...state,
            //   {
            //     _id: Date.now(),
            //     content: message,
            //     user: 'system',
            //     type: 'text',
            //     decisions: block?.decisions || {},
            //     bookingTypes: block?.bookingTypes || {},
            //     link: {text: 'Download', url: download},
            //   },
            // ]);
            setWaiting(false);
            getNextBlock(block.next);

            break;
          }
          case 'scoro': {
            const {data: variables, name} = Chatbot.get('current');
            const {caseTitle, caseDescription, lawyers, user, lawyerEmails} = block;
            setWaiting(true);
            await axios.post('/booking/project', {
              // TODO: optimize
              variables,
              caseTitle: stringFormat(caseTitle, variables),
              caseDescription: stringFormat(caseDescription, variables),
              lawyers,
              user,
              chatBot: name,
              lawyerEmails,
            });
            _scroll();
            setWaiting(false);
            getNextBlock(block.next);
            break;
          }
          case 'booking': {
            setWaiting(true);
            setMessage((state) => [
              ...state,
              {
                _id: Date.now(),
                content: 'Please select booking type',
                user: 'system',
                type: 'booking',
                decisions: block?.decisions || {},
                bookingTypes: block?.types,
              },
            ]);
            setWaiting(false);
            break;
          }
          case 'pipedrive': {
            setWaiting(true);
            setMessage((state) => [
              ...state,
              {
                _id: Date.now(),
                content: block.description,
                user: 'system',
                type: 'pipedrive',
                decisions: block?.decisions || {},
                bookingTypes: block?.types || {},
              },
            ]);
            setWaiting(false);
            break;
          }
          case 'email': {
            setWaiting(true);
            const {data: variables} = Chatbot.get('current');
            const {attachment, cc, bcc, content, subject} = block;
            const emailBlock = {
              email: variables[block.email],
              cc,
              bcc,
              content: stringFormat(content, variables),
              subject: stringFormat(subject, variables),
              attachments: attachment.map((item) => {
                return {id: variables[item], fileName: variables[`${item}-fileName`]};
              }),
            };
            await axios.post('/booking/email', {
              block: emailBlock,
              variables,
              country: meta.country,
              language: meta.language,
            });
            setWaiting(false);
            getNextBlock(block.next);
            break;
          }
          case 'data': {
            Object.entries(block.data).forEach(([key, value]) => {
              Chatbot.setData({[key]: value});
            });
            getNextBlock(block.next);
            break;
          }
          case 'upload': {
            setMessage((state) => [
              ...state,
              {
                _id: Date.now(),
                content: 'Please Upload files',
                user: 'system',
                type: 'upload',
                variable: block.variable,
                sizeLimit: block.sizeLimit,
                acceptedTypes: block.acceptedTypes,
                decisions: block?.decisions || {},
                bookingTypes: block?.types || {},
              },
            ]);
            break;
          }
          case 'multipleChoice': {
            setMessage((state) => [
              ...state,
              {
                _id: Date.now(),
                user: 'system',
                type: 'multipleChoice',
                content: block.content,
                variable: block.variable,
                options: block.options,
                decisions: block?.decisions || {},
                bookingTypes: block?.types || {},
              },
            ]);
            break;
          }

          case 'dieselgate': {
            const {data} = Chatbot.get('current');
            const requestBody = {
              mileageForCalculation: block.mileageForCalculation,
              isUsedCar: data[block.carUsed] === 'true' ? true : false,
              price: Number(data[block.price]),
              carMileage: Number(data[block?.carMileage]),
              usedCarMileage: data[block?.usedCarMileage] ? Number(data[block?.usedCarMileage]) : null,
            };
            const {claim, legalcost} = await axios.post('/chat/dieselgate', requestBody);
            Chatbot.setData({
              [block.legalCost]: legalcost,
              [block.claim]: claim,
            });
            getNextBlock(block.next);
            break;
          }
          case 'sendy': {
            const {data} = Chatbot.get('current');
            const email = data[block.email];
            const sendyId = block.sendyId;
            await axios.post('/chat/sendy', {email, listId: sendyId});
            getNextBlock(block.next);
            break;
          }
          default: {
            if (block.next) {
              getNextBlock(block.next);
            }
            break;
          }
        }
      }
    } else {
      setMessage((state) => [
        ...state,
        {
          _id: Date.now(),
          content: message,
          user: 'system',
          type: 'text',
          decisions: block?.decisions || {},
          bookingTypes: block?.bookingTypes || {},
        },
      ]);
    }
    _scroll();
  };

  const onReply = ({reply, nextId = null}, {resetForm} = {}) => {
    Chatbot.setData({[block.variable]: reply});
    setMessage((state) => [
      ...state,
      {
        content: reply,
      },
    ]);
    _scroll();
    if (nextId) {
      getNextBlock(nextId);
    } else {
      getNextBlock(block.next);
    }
    resetForm && resetForm();
  };

  const onBookingReply = ({reply}) => {
    Chatbot.setData({booking: reply});
    setMessage((state) => [
      ...state,
      {
        content: reply,
      },
    ]);
    _scroll();
  };

  const _scroll = () => {
    const chatBody = document.getElementById('chat-body');
    chatBody.scrollTop = chatBody.scrollHeight;
  };

  const thumbs = files.map((file) => {
    const isImage = file.type.split('/')[0] === 'image';
    return (
      <div className="col-3 position-relative" style={thumb} key={file.name}>
        {isImage && <img src={file.preview} style={img} alt={file.name} />}
        {!isImage && (
          <div className="row align-items-center">
            <div className="col-md-3 offset-md-3">
              <i className="far fa-file fa-3x"></i>
            </div>
            <div className="col offset-md-1">{file.name}</div>
          </div>
        )}
        <button
          id="image-delete-button"
          onClick={() => {
            setFiles(files.filter((i) => i.preview !== file.preview));
          }}
          className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
        >
          <i className="fas fa-trash-alt"></i>
        </button>
      </div>
    );
  });

  return (
    <>
      {state.preview && (
        <div className="chat-preview">
          <div className="chat-header">
            <img src="/images/hugo.legal.png" alt="Hugo legal logo" className="img-fluid" width="100" />
            <div className="mx-2 header-options">
              <div className="pr-1">
                <i className="mx-2 fa fa-sync fa-lg" title="Start Again" onClick={onStartAgain}></i>
                {/* <i className="mx-1 icon fa fa-play"></i> */}
              </div>
            </div>
          </div>
          <div id="chat-body" className="chat-body">
            <div className="container">
              <div className="row justify-content-center mx-2 mt-3">
                <div className="col">
                  <Select
                    placeholder="Select Language"
                    id="language"
                    name="language"
                    options={languages}
                    value={languages.find((item) => item.value === meta.language)}
                    onChange={(option) => {
                      setmeta({...meta, language: option.value});
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderRadius: 0,
                      }),
                    }}
                  />
                </div>
                <div className="col">
                  <Select
                    placeholder="Select Country"
                    id="country"
                    name="country"
                    options={countries}
                    value={countries.find((item) => item.value === meta.country)}
                    onChange={(option) => {
                      setmeta({...meta, country: option.value});
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderRadius: 0,
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
            <hr />
            {messages.map((message, i) => (
              <div key={i}>
                {message.user === 'system' ? (
                  <div key={i} className="message-wrapper">
                    <div className="message-wrapper-row">
                      <img src="/images/chat-logo.png" alt="avatar" className="img-fluid" width="25" height="auto" />
                      <div className="message mx-2">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: stringFormat(message.content, Chatbot.get('current').data),
                          }}
                        ></span>
                        {message?.link && (
                          <a href={message.link.url} target="_blank" rel="noreferrer">
                            <br />
                            {message.link.text}
                          </a>
                        )}
                      </div>
                    </div>

                    {message.type === 'multipleChoice' && (
                      <div id="multiple-choice">
                        <div className="row mx-4">
                          <div className="col-10">
                            <Select
                              options={message.options}
                              ref={optionSelectRef}
                              id="choice"
                              name="choice"
                              placeholder="Select option"
                              className="form-control form-control-sm react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                          <div className="col-2">
                            <button
                              onClick={async () => {
                                const selectedOption = optionSelectRef.current.state.value;
                                if (selectedOption) {
                                  setMessage((state) => [
                                    ...state,
                                    {
                                      content: selectedOption.value,
                                    },
                                  ]);
                                  const nextBlockId = selectedOption.hasUniqueFlow ? selectedOption.next : block.next;
                                  getNextBlock(nextBlockId);
                                  Chatbot.setData({[block.variable]: selectedOption.value});
                                  document.getElementById('multiple-choice').remove();
                                }
                              }}
                              className="btn btn-primary btn-sm mt-1"
                            >
                              <i className="fas fa-share"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {message.type === 'upload' && (
                      <div id="upload-container">
                        <div className="container">
                          <div className="row mx-2">
                            <div className="file-drop-zone col" {...getRootProps()}>
                              <input {...getInputProps({accept: message.acceptedTypes.toString(), multiple: true})} />
                              <p>Drag 'n' drop some files here, or click to select files</p>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center mx-2">{thumbs}</div>
                        <div className="row mx-2">
                          <div className="col">
                            {files.length > 0 && (
                              <button
                                id="upload-submit-button"
                                onClick={async () => {
                                  setWaiting(true);
                                  _scroll();
                                  const formData = new FormData();
                                  files.forEach((file) => {
                                    formData.append('filePrefix', block.filePrefix);
                                    formData.append('files', file);
                                  });
                                  document.getElementById('image-delete-button').remove();
                                  document.getElementById('upload-submit-button').remove();
                                  const {tempFolder} = await axios.post('/chat/upload', formData);
                                  document.getElementById('upload-container').remove();
                                  Chatbot.setData({[block.variable]: tempFolder});
                                  setWaiting(false);
                                  getNextBlock(block.next);
                                  _scroll();
                                }}
                                className="btn btn-primary btn-sm my-2 mx-3"
                              >
                                <i className="fas fa-share"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <div id="message-wrapper-decisions" className="message-wrapper-decisions">
                      {Object.values(message.decisions).map((d, i) => (
                        <span
                          key={i}
                          className="btn btn-outline-primary mx-2 btn-sm"
                          onClick={() => {
                            onReply({reply: d.value, nextId: d.next});
                            document.getElementById('message-wrapper-decisions').remove();
                          }}
                        >
                          {d.text}
                        </span>
                      ))}

                      {message?.timeSlots?.map((slot, i) => (
                        <span
                          key={i}
                          className="btn btn-outline-primary mx-2 btn-sm mb-2"
                          onClick={async () => {
                            _scroll();

                            setMessage((state) => [
                              ...state,
                              {
                                content: `${slot.date} - ${slot.start_time}-${slot.end_time}`,
                              },
                            ]);

                            const {data: variables} = Chatbot.get('current');
                            setWaiting(true);

                            await axios.post('/booking/phone', {
                              variables,
                              block,
                              eventId: slot.id,
                            });
                            setWaiting(false);
                            getNextBlock(block.next);

                            document.getElementById('message-wrapper-decisions').remove();
                            _scroll();
                          }}
                        >
                          {slot.date} - {slot.start_time}-{slot.end_time}
                        </span>
                      ))}

                      {Object.keys(message.bookingTypes).map((type, index) => {
                        return (
                          <span
                            key={index}
                            className="btn btn-outline-primary mx-2 btn-sm"
                            onClick={async () => {
                              _scroll();

                              onBookingReply({reply: type});

                              if (type === 'phone') {
                                const phoneBooking = message.bookingTypes['phone'];
                                if (block.lawyers?.length) {
                                  setWaiting(true);
                                  const {timeSlots} = await axios.post('/booking/timeslots', {
                                    duration: phoneBooking.duration,
                                    lawyers: block.lawyers,
                                  });
                                  setWaiting(false);

                                  setMessage((state) => [
                                    ...state,
                                    {
                                      _id: Date.now(),
                                      content: timeSlots?.length
                                        ? phoneBooking.textSelectTimeSlot
                                        : block.textNoSlotsFound,
                                      user: 'system',
                                      type: 'booking',
                                      decisions: block?.decisions || {},
                                      bookingTypes: {},
                                      timeSlots,
                                    },
                                  ]);
                                  document.getElementById('message-wrapper-decisions').remove();
                                  _scroll();
                                }
                              }
                            }}
                          >
                            {type}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div key={i} className="message-wrapper-user">
                    <div className="message mx-2">{message.content}</div>
                  </div>
                )}
              </div>
            ))}
            {waiting && (
              <div key="indicator" className="message-wrapper">
                <div className="message-wrapper-row">
                  <img src="/images/chat-logo.png" alt="avatar" className="img-fluid" width="25" height="auto" />
                  <div className="message mx-2">
                    <div id="typing-indicator" className="" style={{display: 'block'}}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {block.type === 'input' && (
            <div className="chat-footer">
              <Form initialValues={{reply: ''}} onSuccess={onReply}>
                <div className="input-group">
                  <Field
                    as="input"
                    id="reply"
                    name="reply"
                    className="form-control border-end-0"
                    placeholder="Please enter your text here"
                    required
                  />
                  <span className="input-group-text">
                    <i className="fa fa-paper-plane" aria-hidden="true" role="button" type="submit"></i>
                  </span>
                </div>
              </Form>
            </div>
          )}
        </div>
      )}
      <div className="chat-preview-button">
        <span className="btn btn-primary btn-round" onClick={onChatPreview}>
          <i className="fa fa-headset fa-2x"></i>
        </span>
      </div>
    </>
  );
});
