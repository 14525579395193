import store from '../store';
export class Auth {
  static authenticate() {
    const state = store.getState();
    if (state.user && state.user?.email) {
      return state.user;
    } else {
      return false;
    }
  }
}
