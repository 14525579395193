import React, {useState} from 'react';
import {TwitterPicker} from 'react-color';
import {isLightColor} from '../helpers/block-helper';

export const ColorPicker = ({onChangeComplete, color}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const handleClick = () => {
    setShowColorPicker(!showColorPicker);
  };
  return (
    <>
      <i
        style={{color: isLightColor(color) ? '#000000' : '#ffffff'}}
        className="mx-1 icon fas fa-paint-brush"
        onClick={handleClick}
      ></i>
      {showColorPicker ? (
        <div className="popover nodrag">
          <TwitterPicker triangle="hide" color={color} onChangeComplete={onChangeComplete} />
        </div>
      ) : null}
    </>
  );
};
