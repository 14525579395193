import React, {useRef, useState, useEffect, useContext} from 'react';
import {Handle, useStore} from 'reactflow';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onEdit, isLightColor} from '../../helpers/block-helper';
import {User} from '../../states/actions';

import {PreviewComponent, NodeComponent} from './Components'
import { GlobalStateContext } from '../GlobalState';





export const CommentBlock = (node) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);  
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const {_id} = useSelector(User.get());
  const optionSelectRef = useRef(null);
  const bot = useSelector(Chatbot.get());
  


  if (!block) {
    return null;
  }
  const {data} = block;

  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');



  return (
    <>
     <div className="" id={node.id}>

         <div
            className={`node node-comment`}
            onClick={(e) => {
              e.preventDefault();
              window.el = node.id; 
            }}
          >

                      <div className="node-header">
                        <i className=""><img src={'/images/icons/comment.png'} alt="..." /></i><span> Comment</span> 
                      </div>


              <div className={`preview-message comment`}>
               
                   <p dangerouslySetInnerHTML={{__html: data.content}}></p>
              </div>
          

          </div>


     </div>

    
     
    </>
  );
};
