import React, {useRef, useState, useEffect} from 'react';

import Select from 'react-select';
import {Handle} from 'reactflow';

import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import {ckEditorBasic} from '../../config';
import {ColorPicker} from '../ColorPicker';
import {User} from '../../states/actions';
//import Buttons from 'react-multi-date-picker/components/button';

import {validations, moneyCurrency, ckEditorInputBlock} from '../../config';
import {InputComponent, SelectComp, ButtonState, onEdited, ButtonSwitch} from './Components'

import {DecisionEditor} from './DecisionBlock'
import {CalculationEditor} from './CalculationBlock'
import {ConditionEditor} from './ConditionBlock'
import {MultiplechoiceEditor} from './MultipleChoiceBlock'
import {ReviewEditor} from './ReviewBlock'
import {PaymentEditor} from './PaymentBlock'
import {DataEditor} from './DataBlock'
import {DocumentEditor} from './DocumentBlock'
import {EmailEditor} from './EmailBlock'
import {ShareEditor} from './ShareBlock'
import {UploadEditor} from './UploadBlock'
import {TeleportEditor} from './TeleportBlock'
import {SheetEditor} from './SheetBlock'
import {MatchEditor} from './MatchBlock'
import {ScoroEditor} from './ScoroBlock'
import {AiEditor} from './AiBlock'
import {LoginEditor} from './LoginBlock'
import {CalendarEditor} from './CalendarBlock'


export const BlockEditor = ({node}) => {

  const optionSelectRef = useRef(null);
  const bot = useSelector(Chatbot.get());
  const [modal, showModal] = useState(false);
  const [routeSecondary, setRouteSecondary] = useState(false);

  const inputRef = React.useRef(null);
  const refTxtText = useRef(null);
  const refTxtValue = useRef(null);
  const [loaded, setLoaded] = useState(false);

  
  const block = Chatbot.getBlock(node);
  const {
    data,
    data: {decisions = {}, options ={}, reviews={}},
  } = block;
  const [notification, showNotification] = useState(data.notification !== undefined && data.notification.length > 0 ? true : false);




  const {_id} = useSelector(User.get());



  //Use this to give time to load components with new data. 
  //This is not the best approach.
  useEffect(() => {
    setTimeout(() => {
     setLoaded(true)
    }, "1")
   }, [block]);
  
  const variableOptions = Chatbot.getBlockVariables();

  let variables_inputtype = [
    {
      label: 'Input',
      value: 'input',
      content: 'input',
    },
    {
      label: 'Textarea',
      value: 'textarea',
      content: 'textarea',
    },
    {
      label: 'Signature',
      value: 'signature',
      content: 'signature',
    },
  ];


  if (!block) {
        return null;
  }

/* const handleClick = (e) => {
  console.log("click: ", e.target)
   setInput(e.target.id)
   document.getElementById(input).focus()

 }; */
 
/*  useEffect(() => {
  console.log("Handle input change")
  if(!window.validationError) return null;
  validateInputs(window.validate)
}, [window.handleInput]); */




function validateInputs(el) {
  console.log(el)
  el.forEach(key => {
    const inputElement = document.getElementById(key);
    console.log(key)
    if (inputElement) {
      if (inputElement.value !== "") {
        // Input contains information (is valid)
        inputElement.style.borderColor = 'gray';
         inputElement.classList.add("valid");
        inputElement.classList.remove("invalid"); 
      } else {
        // Input is empty or contains only whitespace (is not valid)
        inputElement.style.borderColor = 'red !important';

        inputElement.classList.add("invalid");
        inputElement.classList.remove("valid"); 
      }
    }
});
}
 
 const handleBlur = (e) => {   
  //e.preventDefault();s
  //console.log("blur", e.target.name)
  //saveData(block.id, e.target.name, e.target.value, e.target);
  Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
 };



function EditorHeader() {
  return (
          <>
           <div className="editor-header">

           <i className=""><img src={'/images/icons/'+data.type+'.png'} alt="..." /></i> 
                  <span> {data.type}</span>  
              <span className='float-right'>
              <i className="mx-1 badge bg-success" onClick={() => {onSetStart(node); onEdited()}}>
                Set home
              </i>
             {/*  <i className="mx-1 badge bg-danger" onClick={() => onRemove(node)}>
                Remove
              </i>

              <i
                className="ms-1 me-2 icon fa fa-times"
                onClick={() => onClose(node)}
              ></i> */}
              </span>
              </div>
          </>
  );
}
function EditorFooter() {
  return (
          <>
            <div className="editor-footer">
                  <label htmlFor="variable" className="form-label">
                    Variable: 
                  </label>
                  <input
                    type="input"
                    id="variable"
                    name="variable"
                    className="form-control"
                    placeholder="name"
                    defaultValue={data.variable}
                    onBlur={(e) => {
                      Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
                    }}
                  />
             </div>
          </>
  );
}
/* function Button() {
  return (
          <>

          <InputComponent data={data} name="Button name" dataname="buttontext" onBlur={handleBlur}/>

          <InputComponent data={data} name="Validation Message" dataname="validationMessage" onBlur={handleBlur}/>

          {block.type === 'textBlock' ? (
              <Buttonstate/>
              ):(<></>)}
          </>
  );
} */
/* 
function Buttonstate(block, label) {    

    //const [buttonstate, setButtonState] = useState(data[dataname]);

    function setActive(e){
      const state = buttonstate ? false : true;
      setButtonState(state); 
      Chatbot.setBlockData(block.id, {button: state}) 
   }

  return (
      <>


        <div className='btn-switch' onClick={(e) => {setActive(e)}}>
              <div  
                    className={`switch ${buttonstate ? 'on' : 'off'}`}>
                    <span></span>
               </div>
              <span className='text'>{label}</span>
        </div>
       
      </> 
  )
} 
*/
function Modal() {   

  if(data.modal !== undefined){
    if(data.modal.length > 0){showModal(true)} 
  }
  return (
    <>
    
    {!modal &&
    <div className="mb-2 mt-2 pointer link" onClick={()=> {showModal(modal ? false : true, modal ? true : false)}}> + Modal</div>  
    }
    {modal && (
      <>
        <br/>
        <div>

        <InputComponent data={data} name="Modal" placeholder="Link name" dataname="modallink" onBlur={handleBlur}/>

         <div id="modal">
            <CKEditor
              config={ckEditorBasic}
              editor={ClassicEditor}
              data={data.modal}
              onChange={onEdited}
              onBlur={(e, editor) => {
                Chatbot.setBlockData(block.id, {modal: editor.getData()});
              }}
            />
          </div>
          </div>
      </> 
      )}
      </>
  )
}

const notification1 = ()  => {
  if(data.notification !== undefined){
    if(data.notification.length > 0){showNotification(true)} 
  } 
}
const Notification = () => {   

  if(data.notification !== undefined){
    if(data.notification.length > 0){showNotification(true)} 
  } 
  return (
    <>
    {!notification && 
      <div className="mb-2 mt-2 pointer link" onClick={()=> {showNotification(notification ? false : true, notification ? true : false)}}> + Notification</div>
    }
    {notification && (
      <>
          <br/>

         <div>
                <label htmlFor="notification" className="form-label comp-header">
                  Notification
                </label>
                <div id="notification">
                  <CKEditor
                    config={ckEditorBasic}
                    editor={ClassicEditor}
                    data={data.notification}
                    onChange={onEdited}
                    onBlur={(e, editor) => {
                      Chatbot.setBlockData(block.id, {notification: editor.getData()});
                      /* if(data.notification.length < 1){showNotification(false)}  */
                    }}
                  />
                </div>
              </div>
      </>
    )} 
    </>
  )
}
const SecondButton = () => {

  if(data.secondbutton !== undefined){
    if(data.secondbutton.length > 0){setRouteSecondary(true)} 
  } 
  return (
    <>
    {!routeSecondary && 
      <div className="mb-2 mt-2 pointer link" onClick={()=> {
        const state = routeSecondary ? false : true ? true : false;
        setRouteSecondary(state)
        if(!state){
        Chatbot.setBlockData(block.id, {next_secondary : ""})
        }
      }
      }> + Add Route</div>
    }
    {routeSecondary && (
      <>
         <br/>
         <InputComponent data={data} name="Route B" dataname="secondbutton" onBlur={handleBlur}/>
      </>
    )} 

    </> 
  )
}
/* const Conts = ( 
    <>
              <div>
              <div id="content">
                <CKEditor
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle('height', '100px', editor.editing.view.document.getRoot());
                    });
                  }}
                  config={
                    {
                    simpleUpload: {
                      uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                    }
                    }
                }
                  
                  editor={ClassicEditor}
                  data={data.content}
                  onBlur={(e, editor) => {
                    Chatbot.setBlockData(block.id, {content: editor.getData()});
                  }}
                />
              </div>
                
            </div>
            </> 
  ) */

const Message = () => {      
  
    return (
        <>
           <div>
                  <div id="notification">
                    <CKEditor
                      config={ckEditorBasic}
                      editor={ClassicEditor}
                      data={data.content}
                      onBlur={(e, editor) => {
                        Chatbot.setBlockData(block.id, {content: editor.getData()});
                      }}
                    />
                  </div>
                </div>
        </> 
    )
   
   
}
const ProgressSelect = () => {
          return (
            <>
                <div className="mb-2">
                      <label htmlFor="botname" className="form-label">
                        Progress
                      </label>
                      <Select             
                      ref={optionSelectRef}
                      autoFocus={false}
                      defaultMenuIsOpen={false}
                      options={bot.progress}
                      id="choice"
                      name="choice"
                      placeholder={data.progress}
                      defaultValue={data.progressheader}
                      menuPlacement="bottom"
                      onBlur={(e) => {
                          if(optionSelectRef.current.state.value){
                              Chatbot.setBlockData(block.id, {isProgress: true, progress: optionSelectRef.current.state.value.label, progressheader: optionSelectRef.current.state.value.value});
                          }
                        }}
                      />
                      <span className="input-group-text btn btn-outline-success border-0" onClick={(e) => Chatbot.setBlockData(block.id, {isProgress: false, progress: ''})}>
                                            <i className="fa fa-minus"></i>
                                    </span>
               </div>
            </>
        )
}




return (

       <>
            
              <EditorHeader/>
              {loaded &&(
              <div className='content'>


                  {/* message */}
                  {(block.type === "commentBlock") && (
                          <div className="mb-2">
                          <label className="form-label comp-header">
                           Comment<i></i>
                          </label>
                        <div id="content">
                          <CKEditor
                            onReady={(editor) => {
                              /* editor.editing.view.change((writer) => {
                                writer.setStyle('height', '100px', editor.editing.view.document.getRoot());
                              }); */
                            }}
                            config={
                              {
                                toolbar: ['bold', 'italic', 'link', 'ctaButton', 'insertImage'],
                                link: {
                                  // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                                  addTargetToExternalLinks: true,
                                },
                              simpleUpload: {
                                uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                              }
                              }
                          }
                            onChange={onEdited}
                            editor={ClassicEditor}
                            data={data.content}
                            onBlur={(e, editor) => {
                              Chatbot.setBlockData(block.id, {content: editor.getData()});
                            }}
                          />
                        </div>
                          
                      </div>
                  )}

                  {(block.type === "inputBlock" || block.type === "textBlock" || block.type === "decisionBlock" || block.type === "multipleChoiceBlock" || block.type === "reviewBlock" || block.type === "paymentBlock" || block.type === "shareBlock" || block.type === "uploadBlock" || block.type === "loginBlock" || block.type === "matchBlock")  &&(
                        
                        <>
                        <br/> <br/>

                       {/*  <ProgressSelect/> */}
  

                         {/* <Message/> */}

                        <InputComponent data={data} name="Message" dataname="headtext" onBlur={handleBlur}/>
                        {/* contnent message */}
                        <div>
                          <div id="content">
                            <CKEditor
                              onReady={(editor) => {
                                /* editor.editing.view.change((writer) => {
                                  writer.setStyle('height', '100px', editor.editing.view.document.getRoot());
                                }); */
                              }}
                              config={
                                {
                                  toolbar: ['bold', 'italic', 'link', 'ctaButton', 'insertImage'],
                                  link: {
                                    // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                                    addTargetToExternalLinks: true,
                                  },
                                simpleUpload: {
                                  uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                                }
                                }
                            }
                              onChange={onEdited}
                              editor={ClassicEditor}
                              data={data.content}
                              onBlur={(e, editor) => {
                                Chatbot.setBlockData(block.id, {content: editor.getData()});
                              }}
                            />
                          </div>
                            
                        </div>

                        {/* <Notification/> */}
                       
                      

                                         
                         <div className="mb-2 mt-2 pointer link" onClick={()=> {showNotification(notification ? false : true, notification ? true : false)}}> + Notification</div>
                          
                        {notification && (
                              <>
                                  <br/>

                                <div>
                                        <label htmlFor="notification" className="form-label comp-header">
                                          Notification
                                        </label>
                                        <div id="notification">
                                          <CKEditor
                                            config={ckEditorBasic}
                                            editor={ClassicEditor}
                                            data={data.notification}
                                            onChange={onEdited}
                                            onBlur={(e, editor) => {
                                              Chatbot.setBlockData(block.id, {notification: editor.getData()});
                                              /* if(data.notification.length < 1){showNotification(false)}  */
                                            }}
                                          />
                                        </div>
                                      </div>
                              </>
                            )} 
                          

                                                  
      
                        <Modal/>
                        
                      </>
                  )}
                  




                  {block.type === "inputBlock" &&(
                      <>
                    <div className='section'>INPUT</div>

                    <SelectComp data={data} name="Input type" dataname="inputtype" options={variables_inputtype} block={block}/>

                    {/* select input type */}
                   {/*  <ButtonSwitch name="Input type" block={block} data={data} dataname="inputtype" label1="" label2="Long input" state1="input" state2="textarea"/> */}

                   <InputComponent data={data} name="Character limit" dataname="characterlimit" onBlur={handleBlur}/>
 
                    <InputComponent data={data} name="Input name" dataname="inputname" onBlur={handleBlur}/>

                    <InputComponent data={data} name="Hint message" dataname="hint" onBlur={handleBlur}/>

                    <SelectComp data={data} name="Validation Rule" dataname="validation" placeholder="Select validation rule" options={validations} block={block}/>

                    {data.validation === 'custom' && (
                     <InputComponent data={data} name="Custom rule" dataname="customrule" onBlur={handleBlur}/>
                    )}

                    {data.validation === 'money' && (
                      <SelectComp data={data} name="Choose Currency" dataname="currency" placeholder="Choose currency" options={moneyCurrency} block={block}/>
                    )}

                    </>
                  )}

                  {block.type === "calculationBlock" &&(
                    <CalculationEditor nodeid={node} block={block} data={data}/>
                  )}

                  {block.type === "conditionBlock" &&(
                    <ConditionEditor nodeid={node} block={block} data={data} />
                  )}

                   {block.type === "dataBlock" &&(
                    <DataEditor nodeid={node} block={block} data={data} />
                  )}

                  {block.type === "teleportBlock" &&(
                    <TeleportEditor nodeid={node} block={block} data={data} reviews={reviews} />
                  )}
                  
                  {block.type === "decisionBlock" &&(
                    <DecisionEditor nodeid={node} block={block} data={data} decisions={decisions} />
                  )}

                  {block.type === "multipleChoiceBlock" &&(
                    <MultiplechoiceEditor nodeid={node} block={block} data={data} options={options} />
                  )}

                  {block.type === "reviewBlock" &&(
                    <ReviewEditor nodeid={node} block={block} data={data} reviews={reviews} />
                  )}

                  {block.type === "shareBlock" &&(
                    <ShareEditor nodeid={node} block={block} data={data} reviews={reviews} />
                  )}
                  
                 {block.type === "uploadBlock" &&(
                    <UploadEditor nodeid={node} block={block} data={data} reviews={reviews} />
                  )}

                  {block.type === "paymentBlock" &&(
                    <PaymentEditor nodeid={node} block={block} data={data} variableoptions={variableOptions}/>
                  )}

                  {block.type === "scoroBlock" &&(
                    <ScoroEditor nodeid={node} block={block} data={data} variableoptions={variableOptions}/>
                  )}


                  {block.type === "documentBlock" &&(
                    <DocumentEditor nodeid={node} block={block} data={data}/>
                  )}

                  {block.type === "sheetBlock" &&(
                    <SheetEditor nodeid={node} block={block} data={data}/>
                  )}

                  {block.type === "aiBlock" &&(
                    <AiEditor nodeid={node} block={block} data={data}/>
                  )}

                  {block.type === "matchBlock" &&(
                    <MatchEditor nodeid={node} block={block} data={data}/>
                  )}

                  {block.type === "loginBlock" &&(
                    <LoginEditor nodeid={node} block={block} data={data}/>
                  )}
                  
                  {block.type === "calendarBlock" &&(
                    <CalendarEditor nodeid={node} block={block} data={data}/>
                  )}
                   
                  {block.type === "emailBlock" &&(
                    <EmailEditor nodeid={node} block={block} data={data}/>
                  )}

                  

                  {(block.type === "inputBlock" || block.type === "multipleChoiceBlock" || block.type === "uploadBlock") &&(
                      <InputComponent data={data} name="Validation Message" dataname="validationMessage" onBlur={handleBlur}/>
                  )}

                  {(block.type === "inputBlock" || block.type === "uploadBlock") &&(
                      <InputComponent data={data} name="Waiting message" dataname="waitmessage" onBlur={handleBlur}/>
                  )}



                  {/* BUTTON */}
                  {(block.type === "inputBlock" || block.type === "decisionBlock" && Object.values(decisions).length > 2 || block.type === "multipleChoiceBlock" || block.type === "textBlock" || block.type === "shareBlock" || block.type === "paymentBlock" || block.type === "reviewBlock" || block.type === "uploadBlock" || block.type === "calendarBlock" || block.type === "matchBlock") &&(
                    <>

                      <div className='section'>BUTTON</div>
                      <InputComponent data={data} name="Button name" dataname="buttontext" onBlur={handleBlur}/>


                      {(block.type === "inputBlock" || block.type === "shareBlock" || block.type === "uploadBlock" || block.type === "calendarBlock" || block.type === "matchBlock" ) &&
                      <SecondButton/>
                      }
                  

                    

                      {block.type === 'textBlock' ? (
                        <>
                        <ButtonState block={block} data={data} startstate={true} dataname="button" label="Active"/>
                        <br/>
                        <ButtonState block={block} data={data} startstate={false} dataname="finish" label="END BLOCK"/>
                         </>
                      ):(<></>)}  
                       {block.type === 'paymentBlock' ? (
                        <>
                        <ButtonState block={block} data={data} startstate={false} dataname="finish" label="END"/>
                         </>
                      ):(<></>)}  

                    </>       
                  )}

                  {/* DATA */}
                  {(block.type === "inputBlock" || block.type === "decisionBlock" || block.type === "multipleChoiceBlock" || block.type === "shareBlock" || block.type === "uploadBlock" || block.type === "calculationBlock" || block.type === "aiBlock") &&(
                    <>
                      <div className='section'>DATA</div>
                      <InputComponent icon="*" data={data} name="Variable" dataname="variable" onBlur={handleBlur}/>
                    </>
                  )}


              </div>
              )}
              
            
                
                
              
          
    </>
    )

}